import { toast } from "react-toastify";

class Util {
  exibirValorMonetarioBr(valor) {
    if (valor != null) {
      return valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    } else {
      let zero = 0.0;
      return zero.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    }
  }

  exibirDataFormatada(data) {
    if (data != null) {
      let dataF = new Date(data);
      return dataF.toLocaleDateString("pt-BR");
    } else {
      return "";
    }
  }

  notify = (mensagem) => toast(mensagem);
  notifyError = (mensagem) => toast.error(mensagem);
  notifySuccess = (mensagem) => toast.success(mensagem);

  tratarErro(error) {
    if (error.response) {
      this.notifyError(error.response.data.message);
    } else {
      this.notifyError("Ocorreu algum erro inesperado.");
    }
  }
  logarErro(error) {
    if (error.response) {
      console.log(error.response.data.message);
    } else {
      console.log("Ocorreu algum erro inesperado");
    }
  }
}

export default new Util();
