import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
		flexDirection: 'column',
    fontSize: "8pt",
    fontFamily: 'Helvetica',
    margin: '10pt 8pt',
  },
  bold:{
    fontFamily: 'Helvetica-Bold',
  },
  disabled:{
    color: "rgba(0,0,0,0.45)"
  },
  mB1:{
    marginBottom: '5pt'
  },
  table:{
    width: '100%',
    border: "1pt solid black",
    borderBottom:'none',
    borderRight:'none',
    marginTop: '20px'
  },
  tableRow:{
    flexDirection: 'row',
    borderBottom:'1pt solid black',
    flexWrap: 'nowrap',
  },
  column:{
    borderRight:'1pt solid black',
    padding: "2pt"
  },
  tableHeaderRow:{
    flexDirection: 'row',
    borderBottom:'1pt solid black',
    borderRight:'1pt solid black',
    flexWrap: 'nowrap',
    fontWeight: 'bold',
  },
  tableHeader:{
    textAlign:'center',
    fontSize:'10pt',
    padding: '7pt',
    width: '100%',
    fontWeight: 'bold',
  },
  tableFooter:{
    fontSize:'9pt',
    padding: '5pt 2pt',
  },
  tableFooterValor:{
    paddingLeft:'15pt'
  },
  textRight:{
    textAlign:'right'
  },
  columnBasic:{
    padding: "2pt"
  },
  c1:{
    width: "25%"
  },
  c2:{
    width: "25%"
  },
  c3:{
    width: "25%"
  },
  c4:{
    width: "25%"
  },
  c5:{
    width: "9%"
  },
  c6:{
    width: "8%"
  },
  c7:{
    width: "9%"
  },
  c8:{
    width: "9%"
  },
  c9:{
    width: "10%"
  },
  c10:{
    width: "10%"
  },
  c11:{
    width: "9%"
  },

  cTotal:{
    width: "9.1%"
  },
  cVTotal:{
    width: "25%",
  },
  colunaPrincipal:{
    border: '1pt solid black',
    marginBottom: '8pt'
  },
  rowHeader: {
    flexDirection: 'row',
    borderBottom: '1pt solid black',
    justifyContent: 'space-between',
    padding: '4pt',
    fontSize: '12pt',
    fontFamily: 'Helvetica-Bold',
  },
  rowDadosPrincipais: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '4pt',  
  },
  rowDadosAprovacao:{
    padding: "4pt",
    fontFamily: 'Helvetica-Bold',
    flexDirection:'row',
    gap: '10pt'
  },
  columnDadosAprovacao:{
    flexDirection: 'column'
  },
  totalBruto:{
    display: 'flex',
    flexDirection:'column',
    justifyContent:'flex-end',
    fontSize:'13pt',
    textAlign:'right'
  }
})

export default function RelatorioPdfGestão ({data, totalComissaoBruta}) {

  function exibirDataFormatada(dataAF) {
    if(dataAF != null){
      let dataF = new Date(dataAF)
      return dataF.toLocaleDateString('pt-BR')
    } else {
      return ""
    }
  }

  function exibirValorMonetarioBr(valor) {
    if (valor != null) {
      return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    } else {
      let zero = 0.0
      return zero.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
    }
  }

  return(
    <Document title={'Relatório PDF'}>
      <Page wrap size='A4'>
        <View style={styles.page} >
          {data.map(fatura => {
            return(
              <View style={styles.colunaPrincipal}>
                <View style={styles.rowHeader}>
                  <Text>Agenciador: {fatura.nomeAgenciador}</Text>
                  <Text>Fatura {fatura.statusFatura}</Text>
                </View>
                <View style={styles.rowDadosPrincipais} >
                  <View style={styles.c1}>
                    <Text style={styles.bold}>Cliente: {fatura.nomeCliente}</Text>
                    <Text>Emissão: {exibirDataFormatada(fatura.dataEmissao)}</Text>
                    <Text>Quitação: {exibirDataFormatada(fatura.dataQuitacao)}</Text>
                    <Text>Vencimento: {exibirDataFormatada(fatura.dataVencimento)}</Text>
                    <Text style={styles.bold}>Transação: {fatura.numeroTransacaoVenda + " - " + fatura.prestacao}</Text>
                    <Text>Região do pedido: {fatura.regiaoPedido}</Text>
                  </View>
                  <View style={styles.c2}>
                    <Text style={styles.bold}>Cobrança: {fatura.tipoCobranca}</Text>
                    <Text>Cod. Profissional: {fatura.codigoProfissional}</Text>
                    <Text>Cod. Vendedor: {fatura.idRCA}</Text>
                    <Text>Dt. Devol: {exibirDataFormatada(fatura.dataDevolucao)}</Text>
                    <Text>Vl. Devol: {fatura.dataDevolucao? exibirValorMonetarioBr(fatura.valorDevolvido) : ""}</Text>
                  </View>
                  <View style={styles.c3}>
                    <Text>Vl. Vendido: {exibirValorMonetarioBr(fatura.valorVendido)}</Text>
                    <Text>Vl. Tabela PSD: {exibirValorMonetarioBr(fatura.valorTabelaPSD)}</Text>
                    <Text>Percentual: {fatura.percentual+"%"}</Text>
                    <Text>Nota: {fatura.numeroNota}</Text>
                    <Text>Parcela: {fatura.prestacao}</Text>
                    <Text>Vl. Parcela: {exibirValorMonetarioBr(fatura.valorParcela)}</Text>
                  </View>
                  <View style={styles.c4}>
                    {fatura.rejeitada === true ?
                      <Text style={styles.bold}>Status da aprovação: Rejeitada</Text>
                      :<Text style={styles.bold}>Status da aprovação: {fatura.aprovacao? "Aprovada" : "Pendente"}</Text>
                    }
                    {fatura.rejeitada === true &&
                      <Text style={styles.bold}>Motivo rejeição: {fatura.motivoRejeicao}</Text>
                    }
                    {fatura.aprovacao === true &&
                      <Text style={styles.bold}>Tipo Comissão: {fatura.tipoComissao? fatura.tipoComissao.replace(/_/g," ").replace("c","C") : ""}</Text>
                    }
                    {fatura.aprovacao === true &&
                      <Text style={[styles.bold,styles.mB1]}>Vl. Comissão: {exibirValorMonetarioBr(fatura.valorComissaoSelecionada)}</Text>
                    }
                    {(fatura.aprovacao === true && fatura.tipoComissao !== "comissao_bruta") && 
                      <Text style={[styles.bold, styles.disabled]} >Comissão bruta: &nbsp; {exibirValorMonetarioBr(fatura.valorComissaoBruta)}</Text>
                    }
                    {(fatura.aprovacao === true && fatura.tipoComissao !== "comissao_com_nfe") && 
                      <Text style={[styles.bold, styles.disabled]} >Comissão com nfe: &nbsp; {exibirValorMonetarioBr(fatura.valorComissaoNfe)}</Text>
                    }
                    {(fatura.aprovacao === true && fatura.tipoComissao !== "comissao_sem_nfe") && 
                      <Text style={[styles.bold, styles.disabled]} >Comissão sem nfe: &nbsp; {exibirValorMonetarioBr(fatura.valorComissaoSemNfe)}</Text>
                    }
                    {(fatura.aprovacao === true && fatura.tipoComissao !== "comissao_compra_boleto") && 
                      <Text style={[styles.bold, styles.disabled]} >Comissão compra/boleto: &nbsp; {exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}</Text>
                    }
                  </View>
                </View>
                <View style={styles.rowDadosAprovacao} >
                  {fatura.aprovacaoComercial === true &&
                    <View style={styles.columnDadosAprovacao}>
                      <Text>Aprovada no Comercial: {fatura.responsavelAprovacaoComercial}</Text>
                      <Text>Data da Aprovação: {exibirDataFormatada(fatura.dataAprovacaoComercial)}</Text>
                    </View>
                  }{ fatura.aprovacaoFinanceiro === true &&
                    <View style={styles.columnDadosAprovacao}>
                      <Text>Aprovada no Financeiro: {fatura.responsavelAprovacaoFinanceiro}</Text>
                      <Text>Data da Aprovação: {exibirDataFormatada(fatura.dataAprovacaoFinanceiro)}</Text>
                    </View>
                  }
                </View>
              </View>
            )
          })}
          <View style={styles.totalBruto}>
            <Text style={styles.bold} >{"Total Comissão Bruta: " + exibirValorMonetarioBr(totalComissaoBruta)}</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}