import { useState } from "react"
import { Link, NavLink } from "react-router-dom"
import { Button, Container, Dropdown, Icon, Image, Menu, Sidebar } from "semantic-ui-react"
import AuthenticationService from "../main/AuthenticationService"

export default function MenuSistema({openModal,perfil,nome}) {

  const [isSideBarOpen, setIsSideBarOpen] = useState(false)

  const abrirSideBar = ( () => {
    setIsSideBarOpen(!isSideBarOpen)
  })

  return(
    <>
      <Menu size="large" className="navbar__menu" fixed="top" color='blue' 
        style={{
          backgroundColor:'white', 
          boxShadow: "0px 1px 2px rgb(50 50 50 / 34%)",
          zIndex: openModal ? "901" : "1003"
        }}
      >
        <Container>
          <Menu.Item 
              icon='bars'
              className='navbar__item--mobile'
              as={Button} 
              onClick={abrirSideBar}
          />
      
          <Menu.Item 
              as={Link} 
              to='/controle-comissoes'
          >
            <Image alt="logo kgm" src='/kgm-lan-logotipo.png' size="small" />
          </Menu.Item>
          {(perfil === "Comercial" || perfil === "Financeiro" || perfil === "Admin") &&
            <Menu.Item
                as={NavLink} 
                to='/gestao-comissoes'
                content='Gestão de Comissões'
                className='navbar__item--pc'
            />
          }
          <Menu.Item
              as={NavLink} 
              to='/consulta-vendas-agenciadas'
              content='Consulta de Vendas Agenciadas'
              className='navbar__item--pc'
          />
          {perfil === "Admin" && 
            <Menu.Item
                as={NavLink} 
                to='/administrar-usuarios'
                content='Administrar Usuários'
                className='navbar__item--pc'
            />
          }
          <Menu.Menu position="right" className="navbar__item--pc" >
            <Dropdown style={{height:'100%'}} item pointing text={nome}>
              <Dropdown.Menu>
                <Dropdown.Item
                  icon='key' 
                  text='Alterar Senha' 
                  as={Link} 
                  to='/alterar-senha'
                />
                <Dropdown.Item
                  icon='power off' 
                  text='Sair' 
                  as={Link} 
                  to='/'
                  onClick={ () => AuthenticationService.logout()}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
          </Container>
      </Menu>
      
      <Sidebar
          as={Menu}
          animation='overlay'
          icon='labeled'
          onHide={() => setIsSideBarOpen(false)}
          vertical
          className='navbar__item--mobile navbar--mobile'
          visible={isSideBarOpen}
          width='thin'
      >
          <Menu.Item
              name='close'
              onClick={abrirSideBar}
              className='navbar__item--mobile--close'
          >
              <Icon 
                  fitted
                  name='close' 
                  size='tiny'
              />
          </Menu.Item>
          {(perfil === "Comercial" || perfil === "Financeiro" || perfil === "Admin") &&
            <Menu.Item
              content='Gestão de Comissões'
              className='navbar__item--mobile'
              as={NavLink} 
              style={{fontWeight: 'bold'}}
              to='/gestao-comissoes'
            />
          }
          <Menu.Item
            content='Consulta de Vendas Agenciadas'
            className='navbar__item--mobile'
            as={NavLink} 
            style={{fontWeight: 'bold'}}
            to='/consulta-vendas-agenciadas'
          />
          {perfil === "Admin" && 
            <Menu.Item
              content='Administrar Usuários'
              className='navbar__item--mobile'
              as={NavLink} 
              style={{fontWeight: 'bold'}}
              to='/administrar-usuarios'
            />
          }
          <Menu.Item>
              <Menu.Header>{nome}</Menu.Header>
              <Menu.Menu>
                <Menu.Item
                  content='Alterar Senha'
                  className='navbar__item--mobile'
                  as={NavLink} 
                  style={{fontWeight: 'bold'}}
                  to='/alterar-senha'
                />
                <Menu.Item
                  content='Sair'
                  className='navbar__item--mobile'
                  as={NavLink} 
                  style={{fontWeight: 'bold'}}
                  to='/'
                  onClick={ () => AuthenticationService.logout()}
                />
              </Menu.Menu>
          </Menu.Item>
      </Sidebar>
      <div className={isSideBarOpen? 'dimmed-screen' : ''}></div>
    </>
  )
}