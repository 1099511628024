import { useEffect,useState } from "react"
import axios from "axios"
import { Button, Container, Form, Header, Icon, Input, Segment } from "semantic-ui-react";
import AuthenticationService from "../main/AuthenticationService";
import { URL_USUARIO } from "../main/api_calls";
import Util from "../main/util";
import MenuSistema from "../components/menu_sistema";

export default function AlterarSenha(){

  const [usuarioLogado, setUsuarioLogado] = useState({})
  const [senhaAtual, setSenhaAtual] = useState("")
  const [novaSenha, setNovaSenha] = useState("")
  const [confirmarSenha, setConfirmarSenha] = useState("")
  const [mostrarSenha, setMostrarSenha] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if(AuthenticationService.isUserLoggedIn()){
      let usuario = AuthenticationService.getUsuario()
      setUsuarioLogado(usuario)
    }else{
      AuthenticationService.logout()
    }
  }, [])

  function atualizarSenha(){

    if (senhaAtual === '') {
      Util.notifyError("O campo Senha atual é de preenchimento obrigatório.")
    } else if (novaSenha === '') {
      Util.notifyError("O campo Nova senha é de preenchimento obrigatório.")
    } else if (confirmarSenha === '') {
      Util.notifyError("O campo Confirme a nova senha é de preenchimento obrigatório.")
    } else {
      if (novaSenha !== confirmarSenha) {
        Util.notifyError("O campo Nova senha e a Confirmação de senha não estão iguais.")
      } else {
        let formData = new FormData()
        formData.append("senhaAtual", senhaAtual)
        formData.append("novaSenha", novaSenha)
        axios.put(URL_USUARIO + "/alterarsenha/" + usuarioLogado.id, formData)
        .then(response => {
          Util.notifySuccess("Senha atualizado com sucesso!")
        })
        .catch(error => {
          Util.tratarErro(error)
        })
      }
    }
  }

  return(
    <div>
      <MenuSistema openModal = {false} perfil={usuarioLogado.perfil}  nome={usuarioLogado.nome}/>
      <Container text>
      <Header as='h2' textAlign="center" color="grey" className="page-header" >Alterar Senha</Header>
      <Segment>
      <Form size='large'>

          <div style={{marginTop: 30}}>
              <Form.Field>
                  <label>Senha atual</label>
                  <Input 
                      icon
                      iconPosition='left'
                      type={mostrarSenha? 'text': 'password'}
                      fluid
                      required
                      value={senhaAtual}
                      onChange={(e) => setSenhaAtual(e.target.value)}
                      placeholder='Senha atual' 
                      action
                      maxLength='24'
                  >
                      <Icon name="lock"/>
                      <input/>
                      <Button icon={mostrarSenha? 'eye' : "eye slash"} basic onClick={e =>setMostrarSenha(!mostrarSenha)}></Button>
                  </Input>
              </Form.Field>
          </div>

          <div style={{marginTop: 20}}>
              <Form.Field>
                  <label>Nova Senha</label>
                  <Input 
                      icon
                      iconPosition='left'
                      type={mostrarSenha? 'text': 'password'}
                      fluid
                      required
                      value={novaSenha}
                      onChange={(e) => setNovaSenha( e.target.value)}
                      placeholder='Nova senha' 
                      action
                      maxLength='24'
                  >
                      <Icon name="lock"/>
                      <input/>
                      <Button icon={mostrarSenha? 'eye' : "eye slash"} basic onClick={e =>setMostrarSenha(!mostrarSenha)}></Button>
                  </Input>
              </Form.Field>
          </div>

          <div style={{marginTop: 20}}>
              <Form.Field>
                  <label>Confirme a nova senha</label>
                  <Input 
                      icon
                      iconPosition='left'
                      type={mostrarSenha? 'text': 'password'}
                      fluid
                      required
                      value={confirmarSenha}
                      onChange={(e) => setConfirmarSenha(e.target.value)}
                      placeholder='Confirme a nova senha'
                      action
                      maxLength='24'
                  >
                      <Icon name="lock"/>
                      <input/>
                      <Button icon={mostrarSenha? 'eye' : "eye slash"} basic onClick={e =>setMostrarSenha(!mostrarSenha)}></Button>
                  </Input>
              </Form.Field>
          </div>
      </Form>
      <div style={{display:'flex', justifyContent:'flex-end'}} >            
        <Button 
          color='blue'
          icon='edit'
          size="large"
          labelPosition='left'
          content='Alterar Senha'
          style={{marginTop:'2rem'}}
          loading={loading}
          onClick={e => atualizarSenha()}
        />
      </div>
      </Segment>
      </Container>
    </div>
  )
}
