import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
		flexDirection: 'column',
    fontSize: "8pt",
    fontFamily: 'Helvetica',
    margin: '0px 10px',
  },
  bold:{
    fontFamily: 'Helvetica-Bold',
  },
  tCenter: {
    textAlign:'center'
  },
  table:{
    width: '100%',
    border: "1pt solid black",
    borderBottom:'none',
    borderRight:'none',
    marginTop: '20px'
  },
  tableRow:{
    flexDirection: 'row',
    borderBottom:'1pt solid black',
    flexWrap: 'nowrap',
  },
  column:{
    borderRight:'1pt solid black',
    padding: "2pt"
  },
  tableHeaderRow:{
    flexDirection: 'row',
    borderBottom:'1pt solid black',
    borderRight:'1pt solid black',
    flexWrap: 'nowrap',
    fontWeight: 'bold',
  },
  tableHeader:{
    textAlign:'center',
    fontSize:'12pt',
    padding: '7pt',
    width: '100%',
    fontWeight: 'bold',
  },
  tableFooter:{
    fontSize:'9pt',
    padding: '5pt 2pt',
  },
  tableFooterValor:{
    paddingLeft:'15pt'
  },
  textRight:{
    textAlign:'right'
  },
  columnBasic:{
    padding: "2pt"
  },
  //c1:{
    //width: "4%"
  //},
  c2:{
    width: "20%"
  },
  c3:{
    width: "15%"
  },
  c4:{
    width: "9%"
  },
  c5:{
    width: "10%"
  },
  c6:{
    width: "9%"
  },
  c7:{
    width: "9%"
  },
  c8:{
    width: "9%"
  },
  c9:{
    width: "10%"
  },
  c10:{
    width: "10%"
  },
  c11:{
    width: "9%"
  },

  cTotal:{
    width: "14.1%"
  },
  cVTotal:{
    width: "20%",
  },
})

export default function RelatorioPdfConsulta ({data, totalQuitadas, totalAVencer, totalVencidas}) {

  function exibirDataFormatada(dataAF) {
    if(dataAF != null){
      let dataF = new Date(dataAF)
      return dataF.toLocaleDateString('pt-BR')
    } else {
      return ""
    }
  }

  function exibirValorMonetarioBr(valor) {
    if (valor != null) {
      return valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    } else {
      let zero = 0.0
      return zero.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
    }
  }

  return(
    <Document title={'Relatório PDF'}>
      <Page wrap size='A4'>
        <View style={styles.page} >
          {/* FATURAS QUITADAS */}
          <View style={styles.table}>
            <View style={[styles.tableHeaderRow]} >
              <Text style={[styles.tableHeader, styles.bold]} >Faturas Quitadas</Text>
            </View>
            <View style={[styles.tableRow]} >
              <Text style={[styles.column, styles.bold, styles.c2]} >Agenciador</Text>
              <Text style={[styles.column, styles.bold, styles.c3]} >Consumidor Final</Text>F
              <Text style={[styles.column, styles.bold, styles.c4]} >Nº NF</Text>
              <Text style={[styles.column, styles.bold, styles.c5]} >Pagamento</Text>
              <Text style={[styles.column, styles.bold, styles.c6]} >Emissão</Text>
              <Text style={[styles.column, styles.bold, styles.c7]} >Vencimento</Text>
              <Text style={[styles.column, styles.bold, styles.c8]} >Valor Bruto</Text>
              <Text style={[styles.column, styles.bold, styles.c9]} >Valor a Pagar</Text>
              <Text style={[styles.column, styles.bold, styles.c11]} >Status da Comissão</Text>
            </View>
            {data.map((fatura) => {
              return(
                <View>
                {fatura.statusFatura === "Quitada" && <View style={[styles.tableRow]} wrap={false} key={fatura.numeroTransacaoVenda + fatura.prestacao}>
                  <Text style={[styles.column, styles.c2]} >{fatura.nomeAgenciador}</Text>
                  <Text style={[styles.column, styles.c3]} >{fatura.nomeCliente}</Text>
                  <Text style={[styles.column, styles.c4, styles.tCenter]} >{fatura.numeroNota + " - " + fatura.prestacao}</Text>
                  <Text style={[styles.column, styles.c5, styles.tCenter]} >{fatura.tipoCobranca}</Text>
                  <Text style={[styles.column, styles.c6]} >{exibirDataFormatada(fatura.dataEmissao)}</Text>
                  <Text style={[styles.column, styles.c7]} >{exibirDataFormatada(fatura.dataVencimento)}</Text>
                  <Text style={[styles.column, styles.c8, styles.textRight]} >{exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}</Text>
                  <Text style={[styles.column, styles.c9, styles.textRight]} >{exibirValorMonetarioBr(fatura.valorAPagar)}</Text>
                  <Text style={[styles.column, styles.c11]} >{fatura.statusPagamentoComissao}</Text>
                </View>}
                </View>
              )
            })}
            <View style={[styles.tableHeaderRow]} >
              <Text style={[ styles.columnBasic, styles.c1]} ></Text>
              <Text style={[ styles.columnBasic, styles.c2]} ></Text>
              <Text style={[ styles.columnBasic, styles.c3]} ></Text>
              <Text style={[ styles.columnBasic, styles.c4]} ></Text>
              <Text style={[ styles.columnBasic, styles.c5]} ></Text>
              <Text style={[ styles.columnBasic, styles.c6]} ></Text>
              <Text style={[ styles.columnBasic, styles.c7]} ></Text>
              <Text style={[styles.column, styles.bold, styles.textRight, styles.cTotal, styles.tableFooter ]} >Total:</Text>
              <Text style={[styles.bold, styles.cVTotal, styles.tableFooter, styles.tableFooterValor ]} >{exibirValorMonetarioBr(totalQuitadas)}</Text>
            </View>
          </View>
          {/* /////////////////////// */}
          {/* FATURAS A VENCER */}
          <View style={styles.table}>
            <View style={[styles.tableHeaderRow]} >
              <Text style={[styles.tableHeader, styles.bold]} >Faturas a Vencer</Text>
            </View>
            <View style={[styles.tableRow]} >
              <Text style={[styles.column, styles.bold, styles.c2]} >Agenciador</Text>
              <Text style={[styles.column, styles.bold, styles.c3]} >Consumidor Final</Text>
              <Text style={[styles.column, styles.bold, styles.c4]} >Nº NF</Text>
              <Text style={[styles.column, styles.bold, styles.c5]} >Pagamento</Text>
              <Text style={[styles.column, styles.bold, styles.c6]} >Emissão</Text>
              <Text style={[styles.column, styles.bold, styles.c7]} >Vencimento</Text>
              <Text style={[styles.column, styles.bold, styles.c8]} >Valor Bruto</Text>
              <Text style={[styles.column, styles.bold, styles.c9]} >Valor a Pagar</Text>
              <Text style={[styles.column, styles.bold, styles.c11]} >Status da Comissão</Text>
            </View>
            {data.map((fatura) => {
              return(
                <View>
                {fatura.statusFatura === "A Vencer" && <View style={[styles.tableRow]} wrap={false} key={fatura.numeroTransacaoVenda + fatura.prestacao}>
                  <Text style={[styles.column, styles.c2]} >{fatura.nomeAgenciador}</Text>
                  <Text style={[styles.column, styles.c3]} >{fatura.nomeCliente}</Text>
                  <Text style={[styles.column, styles.c4, styles.tCenter]} >{fatura.numeroNota + " - " + fatura.prestacao}</Text>
                  <Text style={[styles.column, styles.c5, styles.tCenter]} >{fatura.tipoCobranca}</Text>
                  <Text style={[styles.column, styles.c6]} >{exibirDataFormatada(fatura.dataEmissao)}</Text>
                  <Text style={[styles.column, styles.c7]} >{exibirDataFormatada(fatura.dataVencimento)}</Text>
                  <Text style={[styles.column, styles.c8, styles.textRight]} >{exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}</Text>
                  <Text style={[styles.column, styles.c9, styles.textRight]} >{exibirValorMonetarioBr(fatura.valorAPagar)}</Text>
                  <Text style={[styles.column, styles.c11]} >{fatura.statusPagamentoComissao}</Text>
                </View>}
                </View>
              )
            })}
            <View style={[styles.tableHeaderRow]} >
              <Text style={[ styles.columnBasic, styles.c1]} ></Text>
              <Text style={[ styles.columnBasic, styles.c2]} ></Text>
              <Text style={[ styles.columnBasic, styles.c3]} ></Text>
              <Text style={[ styles.columnBasic, styles.c4]} ></Text>
              <Text style={[ styles.columnBasic, styles.c5]} ></Text>
              <Text style={[ styles.columnBasic, styles.c6]} ></Text>
              <Text style={[ styles.columnBasic, styles.c7]} ></Text>
              <Text style={[styles.column, styles.bold, styles.textRight, styles.cTotal, styles.tableFooter ]} >Total:</Text>
              <Text style={[styles.bold, styles.cVTotal, styles.tableFooter, styles.tableFooterValor ]} >{exibirValorMonetarioBr(totalAVencer)}</Text>
            </View>
          </View>
          {/* /////////////////////// */}
          {/* FATURAS VENCIDAS */}
          <View style={styles.table}>
            <View style={[styles.tableHeaderRow]} >
              <Text style={[styles.tableHeader, styles.bold]} >Faturas Vencidas</Text>
            </View>
            <View style={[styles.tableRow]} >
              <Text style={[styles.column, styles.bold, styles.c2]} >Agenciador</Text>
              <Text style={[styles.column, styles.bold, styles.c3]} >Consumidor FInal</Text>
              <Text style={[styles.column, styles.bold, styles.c4]} >Nº NF</Text>
              <Text style={[styles.column, styles.bold, styles.c5]} >Pagamento</Text>
              <Text style={[styles.column, styles.bold, styles.c6]} >Emissão</Text>
              <Text style={[styles.column, styles.bold, styles.c7]} >Vencimento</Text>
              <Text style={[styles.column, styles.bold, styles.c8]} >Valor Bruto</Text>
              <Text style={[styles.column, styles.bold, styles.c9]} >Valor a Pagar</Text>
              <Text style={[styles.column, styles.bold, styles.c11]} >Status da Comissão</Text>
            </View>
            {data.map((fatura) => {
              return(
                <View>
                {fatura.statusFatura === "Vencida" && <View style={[styles.tableRow]} wrap={false} key={fatura.numeroTransacaoVenda + fatura.prestacao}>
                  <Text style={[styles.column, styles.c2]} >{fatura.nomeAgenciador}</Text>
                  <Text style={[styles.column, styles.c3]} >{fatura.nomeCliente}</Text>
                  <Text style={[styles.column, styles.c4, styles.tCenter]} >{fatura.numeroNota + " - " + fatura.prestacao}</Text>
                  <Text style={[styles.column, styles.c5, styles.tCenter]} >{fatura.tipoCobranca}</Text>
                  <Text style={[styles.column, styles.c6]} >{exibirDataFormatada(fatura.dataEmissao)}</Text>
                  <Text style={[styles.column, styles.c7]} >{exibirDataFormatada(fatura.dataVencimento)}</Text>
                  <Text style={[styles.column, styles.c8, styles.textRight]} >{exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}</Text>
                  <Text style={[styles.column, styles.c9, styles.textRight]} >{exibirValorMonetarioBr(fatura.valorAPagar)}</Text>
                  <Text style={[styles.column, styles.c11]} >{fatura.statusPagamentoComissao}</Text>
                </View>}
                </View>
              )
            })}
            <View style={[styles.tableHeaderRow]} >
              <Text style={[ styles.columnBasic, styles.c1]} ></Text>
              <Text style={[ styles.columnBasic, styles.c2]} ></Text>
              <Text style={[ styles.columnBasic, styles.c3]} ></Text>
              <Text style={[ styles.columnBasic, styles.c4]} ></Text>
              <Text style={[ styles.columnBasic, styles.c5]} ></Text>
              <Text style={[ styles.columnBasic, styles.c6]} ></Text>
              <Text style={[ styles.columnBasic, styles.c7]} ></Text>
              <Text style={[styles.column, styles.bold, styles.textRight, styles.cTotal, styles.tableFooter ]} >Total:</Text>
              <Text style={[styles.bold, styles.cVTotal, styles.tableFooter, styles.tableFooterValor ]} >{exibirValorMonetarioBr(totalVencidas)}</Text>
            </View>
          </View>

          {/* /////////////////////// */}
        </View>
      </Page>
    </Document>
  )
}
