import axios from 'axios'
import { URL_LOGIN } from './api_calls'

export const TOKEN_SESSION_ATTRIBUTE_NAME = 'token'

export const SESSION_USER_ATTRIBUTE_NOME = 'nome'
export const SESSION_USER_ATTRIBUTE_USERNAME = 'username'
export const SESSION_USER_ATTRIBUTE_PERFIL = 'perfil'
export const SESSION_USER_ATTRIBUTE_ID = "id"


class AuthenticationService {

    executeJwtAuthenticationService(username, senha) {

        let authenticationRequest = {
            username: username,
            password:  senha,
        }

        return axios.post(URL_LOGIN, authenticationRequest)
    }

    registerSuccessfulLoginForJwt(dados) {

        localStorage.setItem(TOKEN_SESSION_ATTRIBUTE_NAME, dados.token)
        localStorage.setItem(SESSION_USER_ATTRIBUTE_USERNAME, dados.username)
        localStorage.setItem(SESSION_USER_ATTRIBUTE_PERFIL, dados.perfil)
        localStorage.setItem(SESSION_USER_ATTRIBUTE_NOME, dados.nome)
        localStorage.setItem(SESSION_USER_ATTRIBUTE_ID, dados.id)

        this.setupAxiosInterceptors()
    }

    removerTokens() {
        localStorage.removeItem(TOKEN_SESSION_ATTRIBUTE_NAME)
    }

    getUsuario() {

        let usuario = {
            id: localStorage.getItem(SESSION_USER_ATTRIBUTE_ID),
            nome: localStorage.getItem(SESSION_USER_ATTRIBUTE_NOME),
            perfil: localStorage.getItem(SESSION_USER_ATTRIBUTE_PERFIL),
            username: localStorage.getItem(SESSION_USER_ATTRIBUTE_USERNAME),
        }

        return usuario
    }

    createJWTToken(token) {
        return 'Bearer ' + token
    }

    logout() {
        localStorage.clear() 
        delete axios.defaults.headers.common['Authorization'];
    }

    isUserLoggedIn() {
        let user = localStorage.getItem(TOKEN_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getToken() {
        let token = localStorage.getItem(TOKEN_SESSION_ATTRIBUTE_NAME)
        if (token === null) return ''
        return token
    }

    setupAxiosInterceptors() {
        let token = this.createJWTToken(localStorage.getItem(TOKEN_SESSION_ATTRIBUTE_NAME))

        if (this.isUserLoggedIn()) {
            axios.defaults.headers.common['Authorization'] = token;
        } else {
            delete axios.defaults.headers.common['Authorization'];
        }
    }

}

export default new AuthenticationService()