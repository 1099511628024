import { useEffect, useState } from "react";
import MenuSistema from "../components/menu_sistema";
import { Button, Card, Container, Dimmer, Dropdown, Form, Header, Icon, Loader, Menu, Modal, Pagination, Segment, Table } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import axios from "axios";
import {
  URL_AGENCIADOR,
  URL_COMISSOES,
  URL_FILIAL,
  URL_RCA,
  URL_STATUS_FATURA,
  URL_DADOS_VENDA,
  URL_APROVAR_COMERCIAL,
  URL_APROVAR_FINANCEIRO,
  URL_LANCAMENTO,
  URL_NOVO_RELACIONAMENTO,
  URL_REJEITAR,
  URL_CONSULTAR_STATUS_PGCOM,
  URL_OBTER_RELACIONAMENTO_EMLOTE,
  URL_CONSULTAR_STATUS_APRO,
  URL_CONSULTAR_LOTE,
  URL_USUARIO,
  URL_APROVADO_POR,
  URL_STATUS_PENDENTE,
} from "../main/api_calls";
import Util from "../main/util";
import RelatorioPdfGestão from "./relatorio_pdf_gestao";
import { pdf } from "@react-pdf/renderer";
import AuthenticationService from "../main/AuthenticationService";
import { useNavigate } from "react-router-dom";
import { CHAVE_WINTHOR_SERVICE } from "../constantes";

const selectPgCom = [
  { key: "1", value: "", text: "Selecione" },
  { key: "2", value: "Em Aberto", text: "Em Aberto" },
  { key: "3", value: "Pago", text: "Pago" },
  { key: "4", value: "Parcialmente Pago", text: "Parcialmente Pago" },
];
const selectStatusApro = [
  { key: "1", value: "", text: "Selecione" },
  { key: "2", value: "Aprovada", text: "Aprovada" },
  { key: "3", value: "Pendente", text: "Pendente" },
  { key: "4", value: "Rejeitada", text: "Rejeitada" },
];
const qtdItensLista = [
  { value: "25", text: "25 itens por página", key: "25" },
  { value: "50", text: "50 itens por página", key: "50" },
  { value: "100", text: "100 itens por página", key: "100" },
  // { value: '100000', text: 'Exibir Todos os Itens', key: "100000" },
];

export default function ControleComissoes() {
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({});
  const [menuFiltro, setMenuFiltro] = useState(false);
  const [selecionarVarias, setSelecionarVarias] = useState(false);

  const [listaFiliais, setListaFiliais] = useState([]);
  const [listaAgenciadores, setListaAgenciadores] = useState([]);
  const [listaRCA, setListaRCA] = useState([]);
  const [listaFaturas, setListaFaturas] = useState([]);
  const [listaStatusFatura, setListaStatusFatura] = useState([]);
  const [listaAprovadores, setListaAprovadores] = useState([]);
  const [listaComissoesSelecionadas, setListaComissoesSelecionadas] = useState([]);

  const [faturaModal, setFaturaModal] = useState(null);
  const [openModalAprovacao, setOpenModalAprovacao] = useState(false);
  const [openModalRejeicao, setOpenModalRejeicao] = useState(false);
  const [openModalAprovacaoMultipla, setOpenModalAprovacaoMultipla] = useState(false);
  const [openModalRejeicaoMultipla, setOpenModalRejeicaoMultipla] = useState(false);

  const [filtroFilial, setFiltroFilial] = useState([]);
  const [filtroRca, setFiltroRca] = useState([]);
  const [filtroAgenciador, setFiltroAgenciador] = useState([]);
  const [filtroNota, setFiltroNota] = useState("");
  const [filtroTransacao, setFiltroTransacao] = useState("");
  const [filtroStatusFatura, setFiltroStatusFatura] = useState("");
  const [filtroPgCom, setFiltroPgCom] = useState("");
  const [filtroEmissaoInicial, setFiltroEmissaoInicial] = useState("");
  const [filtroEmissaoFinal, setFiltroEmissaoFinal] = useState("");
  const [filtroStatusAprovacao, setFiltroStatusAprovacao] = useState("");
  const [filtroAprovadoPor, setFiltroAprovadoPor] = useState("");

  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sizeItensLista, setSizeItensLista] = useState("25");
  const [totalRegistros, setTotalRegistros] = useState("");
  const [totalComissaoBruta, setTotalComissaoBruta] = useState(0);

  const [loadingCard, setLoadingCard] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [habilitarPdf, setHabilitarPdf] = useState(false);

  const [formMotivoRejeicao, setFormMotivoRejeicao] = useState("");
  const [formEmailsAprovacao, setFormEmailsAprovacao] = useState("");

  useEffect(() => {
    sessionStorage.setItem("nReq", 0);
    if (AuthenticationService.isUserLoggedIn()) {
      let usuario = AuthenticationService.getUsuario();
      if (usuario.perfil === "Comercial" || usuario.perfil === "Financeiro" || usuario.perfil === "Admin") {
        setUsuario(usuario);
        let formData = new FormData();
        formData.append("chave", CHAVE_WINTHOR_SERVICE);
        axios
          .post(URL_FILIAL, formData)
          .then((response) => {
            carregarListaFilial(response.data);
          })
          .catch((error) => {
            Util.tratarErro(error);
          });

        axios
          .post(URL_AGENCIADOR, formData)
          .then((response) => {
            carregarListaAgenciador(response.data);
          })
          .catch((error) => {
            Util.tratarErro(error);
          });

        axios
          .post(URL_RCA, formData)
          .then((response) => {
            carregarListaRCA(response.data);
          })
          .catch((error) => {
            Util.tratarErro(error);
          });

        axios
          .post(URL_STATUS_FATURA, formData)
          .then((response) => {
            carregarListaStatusFatura(response.data);
          })
          .catch((error) => {
            Util.tratarErro(error);
          });

        axios
          .get(URL_USUARIO)
          .then((response) => {
            carregarListaAprovadores(response.data.filter((user) => user.roles[0] === "Comercial" || user.roles[0] === "Financeiro"));
          })
          .catch((error) => {
            Util.tratarErro(error);
          });
      } else {
        navigate("/consulta-vendas-agenciadas");
      }
    } else {
      AuthenticationService.logout();
    }
  }, []);

  function carregarListaFilial(filiais) {
    let listaF = [];
    filiais.forEach((filial) => {
      let f = {
        key: filial.codigo,
        value: filial.codigo,
        text: filial.razaoSocial + " - " + filial.cidade,
      };
      listaF.push(f);
    });
    setListaFiliais(listaF);
  }

  function carregarListaAgenciador(agenciadores) {
    let listaA = [];
    agenciadores.forEach((a) => {
      let agenciador = {
        key: a.codusur,
        value: a.codusur,
        text: a.codusur + " - " + a.nome,
      };
      listaA.push(agenciador);
    });
    setListaAgenciadores(listaA);
  }

  function carregarListaRCA(rcas) {
    let listaR = [];
    rcas.forEach((r) => {
      let rca = {
        key: r.codusur,
        value: r.codusur,
        text: r.codusur + " - " + r.nome,
      };
      listaR.push(rca);
    });
    setListaRCA(listaR);
  }

  function carregarListaStatusFatura(ObjetoStatusFatura) {
    let listaStatusFatura = Object.values(ObjetoStatusFatura);
    let listaSF = [];
    let statusFatura = {
      key: "n",
      value: "",
      text: "Selecione",
    };
    listaSF.push(statusFatura);
    for (let i = 0; i < listaStatusFatura.length; i++) {
      statusFatura = {
        key: i,
        value: listaStatusFatura[i],
        text: listaStatusFatura[i],
      };
      listaSF.push(statusFatura);
    }
    setListaStatusFatura(listaSF);
  }

  function carregarListaAprovadores(users) {
    let listaU = [];
    let selecione = { key: "n", value: "", text: "Selecione" };
    listaU.push(selecione);
    users.forEach((u) => {
      let user = {
        key: u.id,
        value: u.nome,
        text: u.nome + " - " + u.roles[0],
      };
      listaU.push(user);
    });
    setListaAprovadores(listaU);
  }
  function filtrarComissões() {
    setLoadingCard(true);
    setHabilitarPdf(false);
    sessionStorage.setItem("nReq", parseInt(sessionStorage.getItem("nReq")) + 1);
    let nReqAtual = sessionStorage.getItem("nReq");

    if (filtroPgCom) {
      let formStatusPgCom = new FormData();
      formStatusPgCom.append("status", filtroPgCom);
      formStatusPgCom.append("chave", CHAVE_WINTHOR_SERVICE);
      axios
        .post(URL_CONSULTAR_STATUS_PGCOM, formStatusPgCom)
        .then((response1) => {
          if (response1.data) {
            let listaRecnum = response1.data.map((fatura) => fatura.recnum);
            if (listaRecnum.length > 0) {
              let formRecnum = new FormData();
              formRecnum.append("listaRecnum", listaRecnum);
              axios
                .post(URL_OBTER_RELACIONAMENTO_EMLOTE, formRecnum)
                .then((response2) => {
                  if (response2.data.length > 0) {
                    let listaTransacoes = response2.data.map((fatura) => fatura.comissaoVenda.numeroTransacaoVenda + "-" + fatura.comissaoVenda.prestacao);
                    let formConsultaLote = new FormData();
                    formConsultaLote.append("loteTransacao", listaTransacoes);
                    formConsultaLote.append("size", sizeItensLista);
                    formConsultaLote.append("page", activePage);
                    formConsultaLote.append("chave", CHAVE_WINTHOR_SERVICE);

                    axios
                      .post(URL_CONSULTAR_LOTE, formConsultaLote)
                      .then((response3) => {
                        if (nReqAtual === sessionStorage.getItem("nReq")) {
                          setListaComissoesSelecionadas([]);
                          completarListaFatura(response3.data.content, nReqAtual);
                          setTotalPages(response3.data.totalPages);
                          setTotalRegistros(response3.data.totalElements);
                          setLoadingCard(false);
                        }
                      })
                      .catch((error) => {
                        setLoadingCard(false);
                        setHabilitarPdf(true);
                        if (error.response) {
                          if (error.response.data.message.includes("fromIndex")) {
                            setActivePage(1);
                          } else {
                            Util.tratarErro(error);
                          }
                        } else {
                          this.notifyError("Ocorreu algum erro inesperado.");
                        }
                      });
                  } else {
                    setListaFaturas([]);
                    setLoadingCard(false);
                    setHabilitarPdf(true);
                    setListaComissoesSelecionadas([]);
                    // setActivePage(1);
                    setTotalPages(0);
                    setTotalRegistros(0);
                    setTotalComissaoBruta(0);
                  }
                })
                .catch((error) => {
                  setLoadingCard(false);
                  setHabilitarPdf(true);
                  Util.tratarErro(error);
                });
            } else {
              setListaFaturas([]);
              setLoadingCard(false);
              setHabilitarPdf(true);
              setListaComissoesSelecionadas([]);
              // setActivePage(1);
              setTotalPages(0);
              setTotalRegistros(0);
              setTotalComissaoBruta(0);
            }
          } else {
            setListaFaturas([]);
            setLoadingCard(false);
            setHabilitarPdf(true);
            setListaComissoesSelecionadas([]);
            // setActivePage(1);
            setTotalPages(0);
            setTotalRegistros(0);
            setTotalComissaoBruta(0);
          }
        })
        .catch((error) => {
          setLoadingCard(false);
          setHabilitarPdf(true);
          Util.tratarErro(error);
        });
    } else if (filtroStatusAprovacao) {
      let formStatusApro = new FormData();
      formStatusApro.append("statusAprovacao", filtroStatusAprovacao);
      axios
        .post(URL_CONSULTAR_STATUS_APRO, formStatusApro)
        .then((response1) => {
          if (response1.data.length > 0) {
            let url_consulta = filtroStatusAprovacao === "Pendente" ? URL_STATUS_PENDENTE : URL_CONSULTAR_LOTE;
            let listaTransacoes = response1.data.map((fatura) => fatura.numeroTransacaoVenda + "-" + fatura.prestacao);
            let formConsultaLote = new FormData();
            formConsultaLote.append("loteTransacao", listaTransacoes);
            formConsultaLote.append("size", sizeItensLista);
            formConsultaLote.append("page", activePage);
            formConsultaLote.append("chave", CHAVE_WINTHOR_SERVICE);

            axios
              .post(url_consulta, formConsultaLote)
              .then((response2) => {
                if (nReqAtual === sessionStorage.getItem("nReq")) {
                  setListaComissoesSelecionadas([]);
                  completarListaFatura(response2.data.content, nReqAtual);
                  setTotalPages(response2.data.totalPages);
                  setTotalRegistros(response2.data.totalElements);
                  setLoadingCard(false);
                }
              })
              .catch((error) => {
                setLoadingCard(false);
                setHabilitarPdf(true);
                if (error.response) {
                  if (error.response.data.message.includes("fromIndex")) {
                    setActivePage(1);
                  } else {
                    Util.tratarErro(error);
                  }
                } else {
                  this.notifyError("Ocorreu algum erro inesperado.");
                }
              });
          } else {
            setListaFaturas([]);
            setLoadingCard(false);
            setHabilitarPdf(true);
            setListaComissoesSelecionadas([]);
            // setActivePage(1);
            setTotalPages(0);
            setTotalRegistros(0);
            setTotalComissaoBruta(0);
          }
        })
        .catch((error) => {
          setLoadingCard(false);
          setHabilitarPdf(true);
          Util.tratarErro(error);
        });
    } else if (filtroAprovadoPor) {
      let formAprovadoPor = new FormData();
      formAprovadoPor.append("responsavelAprovacao", filtroAprovadoPor);
      axios
        .post(URL_APROVADO_POR, formAprovadoPor)
        .then((response1) => {
          if (response1.data.length > 0) {
            let listaTransacoes = response1.data.map((fatura) => fatura.numeroTransacaoVenda + "-" + fatura.prestacao);
            let formConsultaLote = new FormData();
            formConsultaLote.append("loteTransacao", listaTransacoes);
            formConsultaLote.append("size", sizeItensLista);
            formConsultaLote.append("page", activePage);
            formConsultaLote.append("chave", CHAVE_WINTHOR_SERVICE);

            axios
              .post(URL_CONSULTAR_LOTE, formConsultaLote)
              .then((response2) => {
                if (nReqAtual === sessionStorage.getItem("nReq")) {
                  setListaComissoesSelecionadas([]);
                  completarListaFatura(response2.data.content, nReqAtual);
                  setTotalPages(response2.data.totalPages);
                  setTotalRegistros(response2.data.totalElements);
                  setLoadingCard(false);
                }
              })
              .catch((error) => {
                setLoadingCard(false);
                setHabilitarPdf(true);
                if (error.response) {
                  if (error.response.data.message.includes("fromIndex")) {
                    setActivePage(1);
                  } else {
                    Util.tratarErro(error);
                  }
                } else {
                  this.notifyError("Ocorreu algum erro inesperado.");
                }
              });
          } else {
            setListaFaturas([]);
            setLoadingCard(false);
            setHabilitarPdf(true);
            setListaComissoesSelecionadas([]);
            // setActivePage(1);
            setTotalPages(0);
            setTotalRegistros(0);
            setTotalComissaoBruta(0);
          }
        })
        .catch((error) => {
          setLoadingCard(false);
          setHabilitarPdf(true);
          Util.tratarErro(error);
        });
    } else {
      let formData = new FormData();
      formData.append("chave", CHAVE_WINTHOR_SERVICE);
      formData.append("filiais", filtroFilial.join(","));
      formData.append("rca", filtroRca.join(","));
      formData.append("idsAgenciador", filtroAgenciador.map(id =>"'" + id + "'").join(","));
      formData.append("numeroNota", filtroNota);
      if (verificarTransacaoValida(filtroTransacao)) {
        formData.append("numeroTransacao", filtroTransacao);
      }
      formData.append("statusFatura", filtroStatusFatura);
      if (filtroEmissaoFinal) {
        formData.append("dataEmissaoInicial", filtroEmissaoFinal.toLocaleDateString("pt-BR"));
      }
      if (filtroEmissaoInicial) {
        formData.append("dataEmissaoFinal", filtroEmissaoInicial.toLocaleDateString("pt-BR"));
      }
      formData.append("size", sizeItensLista);
      formData.append("page", activePage);

      axios
        .post(URL_COMISSOES, formData)
        .then((response) => {
          if (nReqAtual === sessionStorage.getItem("nReq")) {
            setListaComissoesSelecionadas([]);
            completarListaFatura(response.data.content, nReqAtual);
            setTotalPages(response.data.totalPages);
            setTotalRegistros(response.data.totalElements);
            setLoadingCard(false);
          }
        })
        .catch((error) => {
          setLoadingCard(false);
          setHabilitarPdf(true);
          if (error.response) {
            if (error.response.data.message.includes("fromIndex")) {
              setActivePage(1);
            } else {
              Util.tratarErro(error);
            }
          } else {
            this.notifyError("Ocorreu algum erro inesperado.");
          }
        });
    }
  }
  function resetarFiltros() {
    setFiltroFilial([]);
    setFiltroRca([]);
    setFiltroAgenciador([]);
    setFiltroNota("");
    setFiltroTransacao("");
    setFiltroStatusFatura("");
    setFiltroEmissaoInicial("");
    setFiltroEmissaoFinal("");
    setFiltroPgCom("");
    setFiltroAprovadoPor("");
    setFiltroStatusAprovacao("");
  }

  useEffect(() => {
    filtrarComissões();
  }, [
    filtroFilial,
    filtroRca,
    filtroAgenciador,
    filtroNota,
    filtroStatusFatura,
    filtroEmissaoInicial,
    filtroEmissaoFinal,
    filtroPgCom,
    filtroStatusAprovacao,
    filtroAprovadoPor,
    sizeItensLista,
    activePage,
  ]);
  useEffect(() => {
     if (filtroTransacao.length === 0 || filtroTransacao.length > 5) {
      filtrarComissões();
    }
  }, [filtroTransacao]);

  function verificarTransacaoValida(transacao) {
    if (transacao.length >= 6) {
      return true;
    }
    return false;
  }
  
  async function montarPdfGestao(data) {
    const blob = await pdf(<RelatorioPdfGestão data={data} totalComissaoBruta={totalComissaoBruta} />).toBlob();
    let blobURL = URL.createObjectURL(blob);
    window.open(blobURL);
  }

  async function completarListaFatura(listaF, nReqAtual) {
    let totalComissaoB = 0;
    if (listaF.length > 0) {
      let listaFaturasAtualizadas = listaF;
      for (const [i, fatura] of listaFaturasAtualizadas.entries()) {
        const response = await axios.get(URL_DADOS_VENDA + "?numeroTransacaoVenda=" + fatura.numeroTransacaoVenda + "&prestacao=" + fatura.prestacao).catch((error) => {});
        if (nReqAtual === sessionStorage.getItem("nReq")) {
          let faturaAtualizada = fatura;
          if (response != undefined) {
            faturaAtualizada.aprovacao = response.data.aprovada;
            faturaAtualizada.aprovacaoComercial = response.data.aprovacaoComercial;
            faturaAtualizada.aprovacaoFinanceiro = response.data.aprovacaoFinanceiro;
            faturaAtualizada.comissaoPagaNoWinthor = response.data.comissaoPagaNoWinthor;
            faturaAtualizada.dataAprovacaoComercial = response.data.dataAprovacaoComercial;
            faturaAtualizada.dataAprovacaoFinanceiro = response.data.dataAprovacaoFinanceiro;
            faturaAtualizada.dataPagamentoComissaoWinthor = response.data.dataPagamentoComissaoWinthor;
            faturaAtualizada.motivoRejeicao = response.data.motivoRejeicao;
            faturaAtualizada.rejeitada = response.data.rejeitada;
            faturaAtualizada.tipoComissao = response.data.tipoComissao;
            faturaAtualizada.valorComissaoSelecionada = response.data.valorComissaoSelecionada;
            faturaAtualizada.responsavelAprovacaoComercial = response.data.responsavelAprovacaoComercial;
            faturaAtualizada.responsavelAprovacaoFinanceiro = response.data.responsavelAprovacaoFinanceiro;
            faturaAtualizada.valorAtualizado = response.data.valorAtualizado;
          }
          totalComissaoB = totalComissaoB + fatura.valorComissaoBruta;
          faturaAtualizada.terminouLoading = true;
          listaFaturasAtualizadas = listaFaturasAtualizadas.map((fatura) => {
            if (fatura.numeroTransacaoVenda === faturaAtualizada.numeroTransacaoVenda && fatura.prestacao === faturaAtualizada.prestacao) {
              return faturaAtualizada;
            } else {
              return fatura;
            }
          });
          await setListaFaturas(
            listaFaturasAtualizadas.map((fatura) => {
              if (fatura.numeroTransacaoVenda === faturaAtualizada.numeroTransacaoVenda && fatura.prestacao === faturaAtualizada.prestacao) {
                return faturaAtualizada;
              } else {
                return fatura;
              }
            })
          );
        } else {
          break;
        }
      }
      if (nReqAtual === sessionStorage.getItem("nReq")) {
        setTotalComissaoBruta(totalComissaoB);
        setListaFaturas(listaFaturasAtualizadas);
        setHabilitarPdf(true);
        setLoadingCard(false);
      }
    } else {
      setHabilitarPdf(true);
      setListaFaturas([]);
      setTotalComissaoBruta(0);
    }
  }

  function handleChangeComissaoSelecionada(numeroTransacaoVenda, prestacao, tipoComissaoSelecionada, valor, checked, id, nome, fatura) {
    let temComissao = listaComissoesSelecionadas.find((fatura) => fatura.numeroTransacaoVenda === numeroTransacaoVenda && fatura.prestacao === prestacao);
    if (temComissao) {
      if (checked === true) {
        setListaComissoesSelecionadas(
          listaComissoesSelecionadas.map((fatura) => {
            if (fatura.numeroTransacaoVenda === numeroTransacaoVenda && fatura.prestacao === prestacao) {
              return {
                ...fatura,
                radioTipoComissao: tipoComissaoSelecionada,
                radioValorComissao: valor,
                radioEstaSelecionada: true,
              };
            } else {
              return fatura;
            }
          })
        );
      } else {
        setListaComissoesSelecionadas(listaComissoesSelecionadas.filter((fatura) => !(fatura.numeroTransacaoVenda === numeroTransacaoVenda && fatura.prestacao === prestacao)));
      }
    } else {
      if (checked === true) {
        setListaComissoesSelecionadas((prevComissoes) => [
          ...prevComissoes,
          {
            radioTipoComissao: tipoComissaoSelecionada,
            radioValorComissao: valor,
            radioEstaSelecionada: true,
            numeroTransacaoVenda: numeroTransacaoVenda,
            prestacao: prestacao,
            idAgenciador: id,
            nomeAgenciador: fatura ? fatura.nomeAgenciador : "",
            nomeCliente: fatura ? fatura.nomeCliente : "",
            dataVencimento: fatura ? fatura.dataVencimento : "",
            valorComissaoNfe: fatura ? fatura.valorComissaoNfe : "",
            valorComissaoCompraBoleto: fatura ? fatura.valorComissaoCompraBoleto : "",
            valorComissaoSemNfe: fatura ? fatura.valorComissaoSemNfe : "",
            statusFatura: fatura ? fatura.statusFatura : "",
            tipoCobranca: fatura ? fatura.tipoCobranca : "",
          },
        ]);
      }
    }
  }

  function verificarSeComissaoEstaSelecionada(numeroTransacaoVenda, prestacao, tipoComissaoSelecionada, valor, checked) {
    let comissaoSelecionada = listaComissoesSelecionadas.find((fatura) => fatura.numeroTransacaoVenda === numeroTransacaoVenda && fatura.prestacao === prestacao);
    if (comissaoSelecionada) {
      return comissaoSelecionada.radioTipoComissao === tipoComissaoSelecionada ? true : false;
    } else {
      return false;
    }
  }

  function atualizarComissaoAprovada(dados) {
    setListaFaturas(
      listaFaturas.map((fatura) => {
        if (fatura.numeroTransacaoVenda === dados.numeroTransacaoVenda && fatura.prestacao === dados.prestacao) {
          return {
            ...fatura,
            tipoComissao: dados.tipoComissao,
            valorComissaoSelecionada: dados.valorComissaoSelecionada,
            aprovacao: dados.aprovacao,
            aprovacaoComercial: dados.aprovacaoComercial,
            dataAprovacaoComercial: dados.dataAprovacaoComercial,
            responsavelAprovacaoComercial: dados.responsavelAprovacaoComercial,
            aprovacaoFinanceiro: dados.aprovacaoFinanceiro,
            dataAprovacaoFinanceiro: dados.dataAprovacaoFinanceiro,
            responsavelAprovacaoFinanceiro: dados.responsavelAprovacaoFinanceiro,
            comissaoPagaNoWinthor: dados.comissaoPagaNoWinthor,
            dataPagamentoComissaoWinthor: dados.dataPagamentoComissaoWinthor,
            valorAtualizado: dados.valorAtualizado,
            rejeitada: dados.rejeitada,
            motivoRejeicao: dados.motivoRejeicao,
          };
        } else {
          return fatura;
        }
      })
    );
  }

  function handleAprovarComissaoIndividual() {
    setLoadingModal(true);
    let url_aprovacao = faturaModal.radioTipoComissao === "comissao_bruta" ? URL_APROVAR_COMERCIAL : URL_APROVAR_FINANCEIRO;
    let comissao = {
      numeroTransacaoVenda: faturaModal.numeroTransacaoVenda,
      prestacao: faturaModal.prestacao,
      tipoComissao: faturaModal.radioTipoComissao,
      valorComissaoSelecionada: faturaModal.radioValorComissao,
      responsavelAprovacao: usuario.nome,
    };
    if (faturaModal.radioTipoComissao === "comissao_bruta" && formEmailsAprovacao) {
      comissao.emailsAprovacao = formEmailsAprovacao;
      comissao.nomeAgenciador = faturaModal.nomeAgenciador;
      comissao.consumidorFinal = faturaModal.nomeCliente;
      comissao.dataVencimento = faturaModal.dataVencimento ? new Date(faturaModal.dataVencimento).toLocaleDateString("pt-BR") : faturaModal.dataVencimento;
      comissao.valorComNF = faturaModal.valorComissaoNfe;
      comissao.valorCompras = faturaModal.valorComissaoCompraBoleto;
      comissao.valorSemNF = faturaModal.valorComissaoSemNfe;
      comissao.statusParcela = faturaModal.statusFatura;
      comissao.formaPagamento = faturaModal.tipoCobranca;
    }
    axios
      .post(url_aprovacao, comissao)
      .then((response) => {
        atualizarComissaoAprovada(response.data);
        setFormEmailsAprovacao("");
        handleChangeComissaoSelecionada(comissao.numeroTransacaoVenda, comissao.prestacao, comissao.tipoComissao, comissao.valorComissaoSelecionada, false);
        if (response.data.aprovada === true) {
          axios
            .post(URL_LANCAMENTO + "?chave=" + CHAVE_WINTHOR_SERVICE, comissao)
            .then((response2) => {
              comissao.recnum = response2.data.recnum;

              axios
                .post(URL_NOVO_RELACIONAMENTO, comissao)
                .then((response3) => {
                  handleCloseModalAprovacao();
                  setLoadingModal(false);
                  Util.notifySuccess("Comissão aprovacao com sucesso!");
                })
                .catch((error3) => {
                  setLoadingModal(false);
                  Util.tratarErro(error3);
                });
            })
            .catch((error2) => {
              setLoadingModal(false);
              Util.tratarErro(error2);
            });
        } else {
          handleCloseModalAprovacao();
          setLoadingModal(false);
          Util.notifySuccess("Comissão aprovacao com sucesso!");
        }
      })
      .catch((error1) => {
        setLoadingModal(false);
        Util.tratarErro(error1);
      });
  }

  function handleRejeitarComissaoIndividual() {
    setLoadingModal(true);
    let comissao = {
      numeroTransacaoVenda: faturaModal.numeroTransacaoVenda,
      prestacao: faturaModal.prestacao,
      motivoRejeicao: formMotivoRejeicao,
    };
    axios
      .post(URL_REJEITAR, comissao)
      .then((response) => {
        atualizarComissaoAprovada(response.data);
        handleChangeComissaoSelecionada(comissao.numeroTransacaoVenda, comissao.prestacao, "", "", false);
        handleCloseModalRejeicao();
        setFormMotivoRejeicao("");
        setLoadingModal(false);
        Util.notifySuccess("A Comissão foi rejeitada.");
      })
      .catch((error1) => {
        setLoadingModal(false);
        Util.tratarErro(error1);
      });
  }

  async function handleAprovarComissaoMultipla() {
    setLoadingModal(true);
    for (const [i, fatura] of listaComissoesSelecionadas.entries()) {
      let url_aprovacao = fatura.radioTipoComissao === "comissao_bruta" ? URL_APROVAR_COMERCIAL : URL_APROVAR_FINANCEIRO;
      let comissao = {
        numeroTransacaoVenda: fatura.numeroTransacaoVenda,
        prestacao: fatura.prestacao,
        tipoComissao: fatura.radioTipoComissao,
        valorComissaoSelecionada: fatura.radioValorComissao,
        responsavelAprovacao: usuario.nome,
      };

      const response = await axios.post(url_aprovacao, comissao).catch((error1) => {
        Util.tratarErro(error1);
      });
      if (response.data.aprovada === true) {
        const response2 = await axios.post(URL_LANCAMENTO + "?chave=" + CHAVE_WINTHOR_SERVICE, comissao).catch((error2) => {
          Util.tratarErro(error2);
        });
        comissao.recnum = response2.data.recnum;
        const response3 = await axios.post(URL_NOVO_RELACIONAMENTO, comissao).catch((error3) => {
          Util.tratarErro(error3);
        });
        if (response3.ok) {
          Util.notifySuccess("Comissão da fatura " + comissao.numeroTransacaoVenda + "-" + comissao.prestacao + " aprovacao com sucesso!");
        }
      }
    }
    setLoadingModal(false);
    handleCloseModalAprovacaoMultipla();
    filtrarComissões();
  }

  async function handleRejeitarComissaoMultipla() {
    setLoadingModal(true);
    for (const [i, fatura] of listaComissoesSelecionadas.entries()) {
      let comissao = {
        numeroTransacaoVenda: fatura.numeroTransacaoVenda,
        prestacao: fatura.prestacao,
        motivoRejeicao: formMotivoRejeicao,
      };

      const response = await axios.post(URL_REJEITAR, comissao).catch((error1) => {
        Util.tratarErro(error1);
      });
      if (response.ok) {
        Util.notifySuccess("Comissão da fatura " + comissao.numeroTransacaoVenda + "-" + comissao.prestacao + " foi rejeitada.");
      }
    }
    filtrarComissões();
    setLoadingModal(false);
    handleCloseModalRejeicaoMultipla();
    setFormMotivoRejeicao("");
  }

  function handleOpenModalAprovacao(fatura) {
    let temComissao = listaComissoesSelecionadas.find((f) => f.numeroTransacaoVenda === fatura.numeroTransacaoVenda && f.prestacao === fatura.prestacao);
    if (temComissao) {
      setFaturaModal(temComissao);
      setFormEmailsAprovacao(fatura.emailAgenciador);
      setOpenModalAprovacao(true);
    } else {
      Util.notify("Selecione um tipo de comissão.");
    }
  }
  function handleCloseModalAprovacao() {
    setOpenModalAprovacao(false);
    setFormEmailsAprovacao("");
    setFaturaModal(null);
  }

  function handleOpenModalRejeicao(fatura) {
    setFaturaModal(fatura);
    setOpenModalRejeicao(true);
  }

  function handleCloseModalRejeicao() {
    setOpenModalRejeicao(false);
    setFormMotivoRejeicao("");
    setFaturaModal(null);
  }

  function handleOpenModalAprovacaoMultipla() {
    if (listaComissoesSelecionadas.length > 0) {
      setOpenModalAprovacaoMultipla(true);
    } else {
      Util.notify("Selecione um tipo de comissão.");
    }
  }
  function handleCloseModalAprovacaoMultipla() {
    setOpenModalAprovacaoMultipla(false);
  }

  function handleOpenModalRejeicaoMultipla() {
    if (listaComissoesSelecionadas.length > 0) {
      setOpenModalRejeicaoMultipla(true);
    } else {
      Util.notify("Selecione um tipo de comissão.");
    }
  }

  function handleCloseModalRejeicaoMultipla() {
    setOpenModalRejeicaoMultipla(false);
    setFormMotivoRejeicao("");
  }

  return (
    <div>
      <MenuSistema openModal={openModalAprovacao || openModalAprovacaoMultipla || openModalRejeicao || openModalRejeicaoMultipla} perfil={usuario.perfil} nome={usuario.nome} />
      <Container>
        <Header as="h2" textAlign="center" color="grey" className="page-header">
          Gestão de Comissões
        </Header>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
            <Menu compact>
              <Menu.Item name="menuFiltro" active={menuFiltro === true} onClick={() => setMenuFiltro(!menuFiltro)}>
                <Icon name={"filter"} />
                Filtros
              </Menu.Item>
            </Menu>

            <Dropdown style={{ padding: "12px 10px 15px" }} options={qtdItensLista} selection value={sizeItensLista} onChange={(e, { value }) => setSizeItensLista(value)} />
            <Menu compact>
              <Menu.Item name="selecionarVarias" active={selecionarVarias === true} onClick={() => setSelecionarVarias(!selecionarVarias)}>
                <Icon name={selecionarVarias ? "check square" : "check square outline"} />
                Selecionar Várias
              </Menu.Item>
            </Menu>
            <Button
              label="Gerar PDF"
              icon="file pdf"
              color="red"
              disabled={!habilitarPdf}
              title="Clique aqui para gerar um relatório no formato PDF"
              style={{ marginLeft: "1rem" }}
              onClick={() => montarPdfGestao(listaFaturas)}
            />
          </div>
          <Pagination
            boundaryRange={window.innerWidth <= 770 ? 0 : 1}
            siblingRange={window.innerWidth <= 770 ? 0 : 1}
            activePage={activePage}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={totalPages}
            onPageChange={(e, { activePage }) => setActivePage(activePage)}
          />
        </div>
        {menuFiltro && (
          <Segment
            attached="bottom"
            className="filtro filtro-header"
            style={{
              zIndex: openModalAprovacao || openModalAprovacaoMultipla || openModalRejeicao || openModalRejeicaoMultipla ? "900" : "1002",
            }}
          >
            {/* <Header as='h2' textAlign="left" color="grey" className="filtro-header" >Filtros</Header> */}
            <Form size="tiny" className="form-filtros">
              <Form.Group>
                <Form.Select
                  fluid
                  search
                  multiple
                  selection
                  clearable
                  label="Filial"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  placeholder="Filtrar por Filial"
                  options={listaFiliais}
                  value={filtroFilial}
                  onChange={(e, { value }) => setFiltroFilial(value)}
                  width={5}
                />
                <Form.Select
                  fluid
                  search
                  multiple
                  selection
                  clearable
                  label="RCA"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  placeholder="Filtrar por RCA"
                  options={listaRCA}
                  value={filtroRca}
                  onChange={(e, { value }) => setFiltroRca(value)}
                  width={5}
                />
                <Form.Select
                  fluid
                  search
                  multiple
                  selection
                  clearable
                  label="Agenciador"
                  placeholder="Filtrar por Agenciador"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  options={listaAgenciadores}
                  value={filtroAgenciador}
                  onChange={(e, { value }) => setFiltroAgenciador(value)}
                  width={6}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  fluid
                  label="Nº Nota"
                  placeholder="Filtrar por Nº Nota"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  value={filtroNota}
                  onChange={(e, { value }) => setFiltroNota(value)}
                  width={3}
                />
                <Form.Input
                  fluid
                  label="Transação"
                  placeholder="Filtrar por Transação"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  value={filtroTransacao}
                  onChange={(e, { value }) => setFiltroTransacao(value)}
                  width={3}
                />
                <Form.Select
                  fluid
                  search
                  label="Status Fatura"
                  placeholder="Filtrar por Status da Fatura"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  options={listaStatusFatura}
                  value={filtroStatusFatura}
                  onChange={(e, { value }) => setFiltroStatusFatura(value)}
                  width={3}
                />
                <Form.Field className="datepicker-field" disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}>
                  <label>Data Emissão Inicial</label>
                  <div className="datepicker-input">
                    <SemanticDatepicker
                      locale="pt-BR"
                      format="DD/MM/YYYY"
                      disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                      value={filtroEmissaoInicial}
                      onChange={(e, { value }) => setFiltroEmissaoInicial(value)}
                    />
                  </div>
                </Form.Field>
                <Form.Field className="datepicker-field" disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}>
                  <label>Data Emissão Final</label>
                  <div className="datepicker-input">
                    <SemanticDatepicker
                      locale="pt-BR"
                      disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                      format="DD/MM/YYYY"
                      value={filtroEmissaoFinal}
                      onChange={(e, { value }) => setFiltroEmissaoFinal(value)}
                    />
                  </div>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Select
                  fluid
                  search
                  label="Pg Com"
                  placeholder="Filtrar por Pg Com"
                  disabled={
                    filtroStatusAprovacao ||
                    filtroAprovadoPor ||
                    filtroFilial.length > 0 ||
                    filtroRca.length > 0 ||
                    filtroAgenciador.length > 0 ||
                    filtroNota ||
                    filtroTransacao ||
                    filtroStatusFatura ||
                    filtroEmissaoInicial ||
                    filtroEmissaoFinal
                  }
                  options={selectPgCom}
                  value={filtroPgCom}
                  onChange={(e, { value }) => setFiltroPgCom(value)}
                  width={4}
                />
                <Form.Select
                  fluid
                  search
                  label="Status da Aprovação"
                  placeholder="Filtrar por Status da Aprovação"
                  disabled={
                    filtroPgCom ||
                    filtroAprovadoPor ||
                    filtroFilial.length > 0 ||
                    filtroRca.length > 0 ||
                    filtroAgenciador.length > 0 ||
                    filtroNota ||
                    filtroTransacao ||
                    filtroStatusFatura ||
                    filtroEmissaoInicial ||
                    filtroEmissaoFinal
                  }
                  options={selectStatusApro}
                  value={filtroStatusAprovacao}
                  onChange={(e, { value }) => setFiltroStatusAprovacao(value)}
                  width={4}
                />
                <Form.Select
                  fluid
                  search
                  label="Aprovado Por"
                  placeholder="Filtrar por quem aprovou a comissão"
                  disabled={
                    filtroPgCom ||
                    filtroStatusAprovacao ||
                    filtroFilial.length > 0 ||
                    filtroRca.length > 0 ||
                    filtroAgenciador.length > 0 ||
                    filtroNota ||
                    filtroTransacao ||
                    filtroStatusFatura ||
                    filtroEmissaoInicial ||
                    filtroEmissaoFinal
                  }
                  options={listaAprovadores}
                  value={filtroAprovadoPor}
                  onChange={(e, { value }) => setFiltroAprovadoPor(value)}
                  width={4}
                />
                <Button
                  style={{ alignSelf: "end", marginLeft: ".2rem" }}
                  inverted
                  type="button"
                  circular
                  icon="eraser"
                  color="blue"
                  title="Clique aqui para limpar os campos de filtragem"
                  onClick={() => resetarFiltros()}
                />
              </Form.Group>
            </Form>
          </Segment>
        )}
        <Dimmer.Dimmable dimmed>
          <Dimmer active={loadingCard} inverted>
            <Loader active={loadingCard} />
          </Dimmer>
          <Segment>
            {listaFaturas.map((fatura) => {
              return (
                <Card fluid color="blue" key={fatura.numeroTransacaoVenda + fatura.prestacao}>
                  <Card.Content className="fatura__card__header" style={{ paddingBottom: ".3rem", paddingTop: "1rem" }}>
                    <Card.Header>Agenciador: {fatura.nomeAgenciador}</Card.Header>
                    {fatura.statusFatura === "Quitada" && <Card.Header style={{ color: "limegreen" }}>{fatura.statusFatura}</Card.Header>}
                    {fatura.statusFatura === "A Vencer" && <Card.Header style={{ color: "darkorange" }}>{fatura.statusFatura}</Card.Header>}
                    {fatura.statusFatura === "Vencida" && <Card.Header style={{ color: "orangered" }}>{fatura.statusFatura}</Card.Header>}
                  </Card.Content>
                  <Card.Content>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".5rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          marginBottom: ".5rem",
                          gap: ".5rem",
                          justifyContent: "space-between",
                          padding: ".1rem 1rem",
                        }}
                      >
                        <div className="fatura__card__column" style={{ display: "flex", flexDirection: "column" }}>
                          <span>
                            <b>Cliente: &nbsp; {fatura.nomeCliente}</b>
                          </span>
                          <span>Emissão: &nbsp; {Util.exibirDataFormatada(fatura.dataEmissao)}</span>
                          <span>Quitação: &nbsp; {Util.exibirDataFormatada(fatura.dataQuitacao)}</span>
                          <span>Vencimento: &nbsp; {Util.exibirDataFormatada(fatura.dataVencimento)}</span>
                          <span>
                            Transação: <b>{fatura.numeroTransacaoVenda + "-" + fatura.prestacao}</b>
                          </span>
                          <span>
                            Região do pedido: &nbsp; <b>{fatura.regiaoPedido}</b>{" "}
                          </span>
                        </div>
                        <div className="fatura__card__column" style={{ display: "flex", flexDirection: "column" }}>
                          <span>
                            Cobrança: &nbsp; <b>{fatura.tipoCobranca}</b>
                          </span>
                          <span>Cod. Profissional: &nbsp; {fatura.codigoProfissional}</span>
                          <span>Cod. Vendedor: &nbsp; {fatura.idRCA}</span>
                          <span>Dt. Devol: &nbsp; {Util.exibirDataFormatada(fatura.dataDevolucao)}</span>
                          <span>Vl. Devol: &nbsp; {fatura.dataDevolucao ? Util.exibirValorMonetarioBr(fatura.valorDevolvido) : ""}</span>
                        </div>
                        <div className="fatura__card__column" style={{ display: "flex", flexDirection: "column" }}>
                          <span>Vl. Vendido: &nbsp; {Util.exibirValorMonetarioBr(fatura.valorVenvido)}</span>
                          <span>Vl. Tabela PSD: &nbsp; {Util.exibirValorMonetarioBr(fatura.valorTabelaPSD)}</span>
                          <span>Percentual: &nbsp; {fatura.percentual + "%"}</span>
                          <span>Nota: &nbsp; {fatura.numeroNota}</span>
                          <span>Parcela: &nbsp; {fatura.prestacao}</span>
                          <span>Vl. Parcela: &nbsp; {Util.exibirValorMonetarioBr(fatura.valorParcela)}</span>
                        </div>
                        <Dimmer.Dimmable style={{ width: "25%" }} className="fatura_card_column--radio" dimmed>
                          <Dimmer active={!fatura.terminouLoading} inverted>
                            <Loader active={!fatura.terminouLoading} />
                          </Dimmer>
                          <div
                            className="fatura__card__column fatura_card_column--radio"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            {fatura.rejeitada === true ? (
                              <span style={{ fontWeight: "bold" }}>
                                Status da aprovação: &nbsp; <b style={{ color: "red" }}>{"Rejeitada"}</b>
                              </span>
                            ) : (
                              <span style={{ fontWeight: "bold" }}>
                                Status da aprovação: &nbsp;{" "}
                                <b
                                  style={{
                                    color: fatura.aprovacao ? "royalblue" : "gold",
                                  }}
                                >
                                  {fatura.aprovacao ? "Aprovada" : "Pendente"}
                                </b>
                              </span>
                            )}
                            {fatura.rejeitada === true && <span style={{ fontWeight: "bold" }}>Motivo rejeição: &nbsp; {fatura.motivoRejeicao}</span>}
                            {fatura.aprovacao === true && (
                              <>
                                <span>
                                  <b>Tipo Comissão: &nbsp; {fatura.tipoComissao ? fatura.tipoComissao.replace(/_/g, " ").replace("c", "C") : ""}</b>
                                </span>
                                <span>
                                  <b>Vl. Comissão: &nbsp; {Util.exibirValorMonetarioBr(fatura.valorComissaoSelecionada)}</b>
                                </span>
                                <br />
                                {fatura.tipoComissao !== "comissao_bruta" && (
                                  <span style={{ opacity: ".45" }}>
                                    <b>Comissão bruta: &nbsp; {Util.exibirValorMonetarioBr(fatura.valorComissaoBruta)}</b>
                                  </span>
                                )}
                                {fatura.tipoComissao !== "comissao_com_nfe" && (
                                  <span style={{ opacity: ".45" }}>
                                    <b>Comissão com nfe: &nbsp; {Util.exibirValorMonetarioBr(fatura.valorComissaoNfe)}</b>
                                  </span>
                                )}
                                {fatura.tipoComissao !== "comissao_sem_nfe" && (
                                  <span style={{ opacity: ".45" }}>
                                    <b>Comissão sem nfe: &nbsp; {Util.exibirValorMonetarioBr(fatura.valorComissaoSemNfe)}</b>
                                  </span>
                                )}
                                {fatura.tipoComissao !== "comissao_compra_boleto" && (
                                  <span style={{ opacity: ".45" }}>
                                    <b>Comissão compra/boleto: &nbsp; {Util.exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}</b>
                                  </span>
                                )}
                              </>
                            )}
                            {!(fatura.aprovacao === true || fatura.rejeitada === true || !fatura.terminouLoading) && (
                              <Form className="fatura__card__radio">
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    justifyContent: "normal",
                                    gap: "0.5rem",
                                  }}
                                >
                                  <span
                                    style={{
                                      textAlign: "right",
                                      width: "15rem",
                                    }}
                                  >
                                    <b>Comissão bruta:</b>
                                  </span>
                                  <Form.Radio
                                    value="comissao_bruta"
                                    disabled={fatura.aprovacaoComercial || usuario.perfil !== "Comercial"}
                                    checked={verificarSeComissaoEstaSelecionada(fatura.numeroTransacaoVenda, fatura.prestacao, "comissao_bruta", fatura.valorComissaoBruta)}
                                    onClick={(e, { checked }) =>
                                      handleChangeComissaoSelecionada(
                                        fatura.numeroTransacaoVenda,
                                        fatura.prestacao,
                                        "comissao_bruta",
                                        fatura.valorComissaoBruta,
                                        checked,
                                        fatura.idAgenciador,
                                        fatura.nomeAgenciador,
                                        fatura
                                      )
                                    }
                                    label={Util.exibirValorMonetarioBr(fatura.valorComissaoBruta)}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    justifyContent: "normal",
                                    gap: "0.5rem",
                                  }}
                                >
                                  <span
                                    style={{
                                      textAlign: "right",
                                      width: "15rem",
                                    }}
                                  >
                                    <b>Comissão com nfe:</b>
                                  </span>
                                  <Form.Radio
                                    value="comissao_com_nfe"
                                    disabled={!fatura.aprovacaoComercial || fatura.aprovacaoFinanceiro || usuario.perfil !== "Financeiro"}
                                    checked={verificarSeComissaoEstaSelecionada(fatura.numeroTransacaoVenda, fatura.prestacao, "comissao_com_nfe", fatura.valorComissaoBruta)}
                                    onClick={(e, { checked }) =>
                                      handleChangeComissaoSelecionada(
                                        fatura.numeroTransacaoVenda,
                                        fatura.prestacao,
                                        "comissao_com_nfe",
                                        fatura.valorComissaoNfe,
                                        checked,
                                        fatura.idAgenciador,
                                        fatura.nomeAgenciador,
                                        fatura
                                      )
                                    }
                                    label={Util.exibirValorMonetarioBr(fatura.valorComissaoNfe)}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    justifyContent: "normal",
                                    gap: "0.5rem",
                                  }}
                                >
                                  <span
                                    style={{
                                      textAlign: "right",
                                      width: "15rem",
                                    }}
                                  >
                                    <b>Comissão sem nfe:</b>
                                  </span>
                                  <Form.Radio
                                    value="comissao_sem_nfe"
                                    checked={verificarSeComissaoEstaSelecionada(fatura.numeroTransacaoVenda, fatura.prestacao, "comissao_sem_nfe", fatura.valorComissaoBruta)}
                                    disabled={!fatura.aprovacaoComercial || fatura.aprovacaoFinanceiro || usuario.perfil !== "Financeiro"}
                                    onClick={(e, { checked }) =>
                                      handleChangeComissaoSelecionada(
                                        fatura.numeroTransacaoVenda,
                                        fatura.prestacao,
                                        "comissao_sem_nfe",
                                        fatura.valorComissaoSemNfe,
                                        checked,
                                        fatura.idAgenciador,
                                        fatura.nomeAgenciador,
                                        fatura
                                      )
                                    }
                                    label={Util.exibirValorMonetarioBr(fatura.valorComissaoSemNfe)}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    justifyContent: "normal",
                                    gap: "0.5rem",
                                  }}
                                >
                                  <span
                                    style={{
                                      textAlign: "right",
                                      width: "15rem",
                                    }}
                                  >
                                    <b>Comissão compra/boleto:</b>
                                  </span>
                                  <Form.Radio
                                    value="comissao_compra_boleto"
                                    checked={verificarSeComissaoEstaSelecionada(fatura.numeroTransacaoVenda, fatura.prestacao, "comissao_compra_boleto", fatura.valorComissaoBruta)}
                                    disabled={!fatura.aprovacaoComercial || fatura.aprovacaoFinanceiro || usuario.perfil !== "Financeiro"}
                                    onClick={(e, { checked }) =>
                                      handleChangeComissaoSelecionada(
                                        fatura.numeroTransacaoVenda,
                                        fatura.prestacao,
                                        "comissao_compra_boleto",
                                        fatura.valorComissaoCompraBoleto,
                                        checked,
                                        fatura.idAgenciador,
                                        fatura.nomeAgenciador,
                                        fatura
                                      )
                                    }
                                    label={Util.exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}
                                  />
                                </div>
                              </Form>
                            )}
                          </div>
                        </Dimmer.Dimmable>
                      </div>
                      <Dimmer.Dimmable dimmed>
                        <Dimmer active={!fatura.terminouLoading} inverted>
                          <Loader active={!fatura.terminouLoading} />
                        </Dimmer>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: "2rem",
                          }}
                        >
                          {fatura.aprovacaoComercial === true && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "wrap",
                              }}
                            >
                              <span>
                                Aprovada no Comercial: &nbsp;
                                <b>{fatura.responsavelAprovacaoComercial}</b>
                              </span>
                              <span>
                                Data da Aprovação: &nbsp;
                                <b>{Util.exibirDataFormatada(fatura.dataAprovacaoComercial + " 00:00:00.0")}</b>
                              </span>
                            </div>
                          )}
                          {fatura.aprovacaoFinanceiro === true && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "wrap",
                              }}
                            >
                              <span>
                                Aprovada no Financeiro: &nbsp;
                                <b>{fatura.responsavelAprovacaoFinanceiro}</b>
                              </span>
                              <span>
                                Data da Aprovação: &nbsp;
                                <b>{Util.exibirDataFormatada(fatura.dataAprovacaoFinanceiro + " 00:00:00.0")}</b>
                              </span>
                            </div>
                          )}
                        </div>
                      </Dimmer.Dimmable>
                    </div>
                  </Card.Content>
                  {!(fatura.aprovacao === true || fatura.rejeitada === true || !fatura.terminouLoading) && !(fatura.aprovacaoComercial || usuario.perfil !== "Comercial") && (
                    <Card.Content style={{ paddingBottom: ".6rem", paddingTop: ".4rem" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button color={selecionarVarias ? "grey" : "red"} size="tiny" disabled={selecionarVarias} onClick={() => handleOpenModalRejeicao(fatura)}>
                          Rejeitar Comissão
                        </Button>
                        <Button color={selecionarVarias ? "grey" : "blue"} size="tiny" disabled={selecionarVarias} onClick={() => handleOpenModalAprovacao(fatura)}>
                          Aprovar Comissão
                        </Button>
                      </div>
                    </Card.Content>
                  )}
                  {!(fatura.aprovacao === true || fatura.rejeitada === true || !fatura.terminouLoading) &&
                    !(!fatura.aprovacaoComercial || fatura.aprovacaoFinanceiro || usuario.perfil !== "Financeiro") && (
                      <Card.Content style={{ paddingBottom: ".6rem", paddingTop: ".4rem" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button color={selecionarVarias ? "grey" : "red"} size="tiny" disabled={selecionarVarias} onClick={() => handleOpenModalRejeicao(fatura)}>
                            Rejeitar Comissão
                          </Button>
                          <Button color={selecionarVarias ? "grey" : "blue"} size="tiny" disabled={selecionarVarias} onClick={() => handleOpenModalAprovacao(fatura)}>
                            Aprovar Comissão
                          </Button>
                        </div>
                      </Card.Content>
                    )}
                </Card>
              );
            })}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Dimmer.Dimmable dimmed>
                <Dimmer active={!habilitarPdf} inverted>
                  <Loader active={!habilitarPdf}></Loader>
                </Dimmer>
                <p
                  style={{
                    textAlign: "right",
                    fontSize: "1.3rem",
                    padding: "3px",
                  }}
                >
                  <b>{"Total comissão bruta: " + Util.exibirValorMonetarioBr(totalComissaoBruta)}</b>
                </p>
              </Dimmer.Dimmable>
            </div>
          </Segment>
        </Dimmer.Dimmable>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            paddingBottom: "5rem",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <p style={{ textAlign: "right", fontSize: "1.2rem" }}>
              <b>{totalRegistros} registros listados</b>
            </p>
          </div>
          <Pagination
            boundaryRange={window.innerWidth <= 770 ? 0 : 1}
            siblingRange={window.innerWidth <= 770 ? 0 : 1}
            activePage={activePage}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            totalPages={totalPages}
            onPageChange={(e, { activePage }) => setActivePage(activePage)}
          />
        </div>

        <Menu
          secondary
          className={selecionarVarias ? "menu__selecionar-varias--active" : "menu__selecionar-varias--deactive"}
          compact
          widths={2}
          fixed="bottom"
          style={{
            position: "sticky",
            zIndex: openModalAprovacao || openModalAprovacaoMultipla || openModalRejeicao || openModalRejeicaoMultipla ? "900" : "999",
            bottom: "1rem",
          }}
        >
          {/* <Container text textAlign="center" style={{gap:'1rem'}}> */}
          <div
            style={{
              display: "flex",
              gap: "1rem",
              backgroundColor: "rgba(0,0,0,0.7)",
              boxShadow: "0px 0px 2px rgb(50 50 50 / 34%)",
              padding: "0.5rem 4rem 0.5rem 3rem",
            }}
          >
            <Menu.Item>
              <Button style={{ fontSize: "1.2rem" }} color="red" onClick={() => handleOpenModalRejeicaoMultipla()}>
                Rejeitar Comissões
              </Button>
            </Menu.Item>
            <Menu.Item>
              <Button style={{ fontSize: "1.2rem" }} color="blue" onClick={() => handleOpenModalAprovacaoMultipla()}>
                Aprovar Comissões
              </Button>
            </Menu.Item>
          </div>
          {/* </Container> */}
        </Menu>
      </Container>

      <Modal size="tiny" centered closeIcon={false} closeOnDimmerClick={loadingModal ? false : true} open={openModalAprovacao} onClose={() => handleCloseModalAprovacao()}>
        {faturaModal && openModalAprovacao && (
          <>
            <Modal.Header
              style={{
                textAlign: "center",
                backgroundColor: loadingModal ? "lightblue" : "dodgerblue",
                color: loadingModal ? "darkgrey" : "black",
              }}
            >
              Deseja Aprovar esta comissão?
            </Modal.Header>
            <Modal.Content style={{ textAlign: "center", fontSize: "1.5rem" }}>
              <Dimmer.Dimmable dimmed>
                <Dimmer active={loadingModal} inverted>
                  <Loader active={loadingModal}></Loader>
                </Dimmer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <span>{faturaModal.radioTipoComissao.replace(/_/g, " ").replace("c", "C") + ":"}</span>
                  <span>
                    <b>{Util.exibirValorMonetarioBr(faturaModal.radioValorComissao)}</b>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <span>Agenciador:</span>
                  <span>
                    <b>{faturaModal.idAgenciador + " - " + faturaModal.nomeAgenciador}</b>
                  </span>
                </div>
              </Dimmer.Dimmable>
            </Modal.Content>
            <Modal.Actions style={{ textAlign: "center" }}>
              <Dimmer.Dimmable dimmed>
                <Dimmer active={loadingModal} inverted></Dimmer>
                <Form style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                  {faturaModal.radioTipoComissao === "comissao_bruta" && (
                    <Form.Input
                      type="email"
                      placeholder="Digite o Email"
                      style={{ textAlign: "left", marginTop: "1rem" }}
                      label="Enviar notificação por Email para o Agenciador? (Opcional)"
                      value={formEmailsAprovacao}
                      onChange={(e) => setFormEmailsAprovacao(e.target.value)}
                    />
                  )}
                </Form>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button onClick={() => handleCloseModalAprovacao()}>Não</Button>
                  <Button color="blue" onClick={() => handleAprovarComissaoIndividual()}>
                    Aprovar Comissão
                  </Button>
                </div>
              </Dimmer.Dimmable>
            </Modal.Actions>
          </>
        )}
      </Modal>
      <Modal size="tiny" centered closeIcon={false} closeOnDimmerClick={loadingModal ? false : true} open={openModalRejeicao} onClose={() => handleCloseModalRejeicao()}>
        {faturaModal && openModalRejeicao && (
          <>
            <Modal.Header
              style={{
                textAlign: "center",
                backgroundColor: loadingModal ? "rgba(300,0,0,0.5)" : "indianred",
                color: loadingModal ? "darkgrey" : "black",
              }}
            >
              Deseja Rejeitar esta comissão?
            </Modal.Header>
            <Modal.Content style={{ textAlign: "center", fontSize: "1.5rem" }}>
              <Dimmer.Dimmable dimmed>
                <Dimmer active={loadingModal} inverted>
                  <Loader active={loadingModal}></Loader>
                </Dimmer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <span>Transação:</span>
                  <span>
                    <b>{faturaModal.numeroTransacaoVenda + "-" + faturaModal.prestacao}</b>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <span>Agenciador:</span>
                  <span>
                    <b>{faturaModal.idAgenciador + " - " + faturaModal.nomeAgenciador}</b>
                  </span>
                </div>
              </Dimmer.Dimmable>
            </Modal.Content>
            <Modal.Actions style={{ textAlign: "center" }}>
              <Dimmer.Dimmable dimmed>
                <Dimmer active={loadingModal} inverted></Dimmer>
                <Form style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                  <Form.TextArea
                    style={{ textAlign: "left" }}
                    placeholder="Digite aqui o motivo da rejeição"
                    label="Informe o motivo da rejeição"
                    onChange={(e) => setFormMotivoRejeicao(e.target.value)}
                  />
                </Form>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button onClick={() => handleCloseModalRejeicao()}>Não</Button>
                  <Button negative onClick={() => handleRejeitarComissaoIndividual()}>
                    Rejeitar Comissão
                  </Button>
                </div>
              </Dimmer.Dimmable>
            </Modal.Actions>
          </>
        )}
      </Modal>
      <Modal
        size="small"
        centered
        closeIcon={false}
        closeOnDimmerClick={loadingModal ? false : true}
        open={openModalAprovacaoMultipla}
        onClose={() => handleCloseModalAprovacaoMultipla()}
      >
        {listaComissoesSelecionadas.length > 0 && (
          <>
            <Modal.Header
              style={{
                backgroundColor: "dodgerblue",
                textAlign: "center",
                backgroundColor: loadingModal ? "lightblue" : "dodgerblue",
                color: loadingModal ? "darkgrey" : "black",
              }}
            >
              Deseja Aprovar estas comissões?
            </Modal.Header>
            <Modal.Content style={{ fontSize: "1.1rem" }}>
              <Dimmer.Dimmable dimmed>
                <Dimmer active={loadingModal} inverted>
                  <Loader active={loadingModal} />
                </Dimmer>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Agenciador</Table.HeaderCell>
                      <Table.HeaderCell>Transação</Table.HeaderCell>
                      <Table.HeaderCell>Comissão</Table.HeaderCell>
                      <Table.HeaderCell>Valor</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {listaComissoesSelecionadas.map((fatura) => (
                      <Table.Row>
                        <Table.Cell>{fatura.idAgenciador + " - " + fatura.nomeAgenciador}</Table.Cell>
                        <Table.Cell>{fatura.numeroTransacaoVenda + "-" + fatura.prestacao}</Table.Cell>
                        <Table.Cell>{fatura.radioTipoComissao}</Table.Cell>
                        <Table.Cell textAlign="right">{Util.exibirValorMonetarioBr(fatura.radioValorComissao)}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Dimmer.Dimmable>
            </Modal.Content>
            <Modal.Actions style={{ textAlign: "center" }}>
              <Dimmer.Dimmable dimmed>
                <Dimmer active={loadingModal} inverted></Dimmer>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button onClick={() => handleCloseModalAprovacaoMultipla()}>Não</Button>
                  <Button color="blue" onClick={() => handleAprovarComissaoMultipla()}>
                    Aprovar Comissões
                  </Button>
                </div>
              </Dimmer.Dimmable>
            </Modal.Actions>
          </>
        )}
      </Modal>
      <Modal
        size="small"
        centered
        closeIcon={false}
        closeOnDimmerClick={loadingModal ? false : true}
        open={openModalRejeicaoMultipla}
        onClose={() => handleCloseModalRejeicaoMultipla()}
      >
        {listaComissoesSelecionadas.length > 0 && (
          <>
            <Modal.Header
              style={{
                textAlign: "center",
                backgroundColor: loadingModal ? "rgba(300,0,0,0.5)" : "indianred",
                color: loadingModal ? "darkgrey" : "black",
              }}
            >
              Deseja Rejeitar estas comissões?
            </Modal.Header>
            <Modal.Content style={{ fontSize: "1.1rem" }}>
              <Dimmer.Dimmable dimmed>
                <Dimmer active={loadingModal} inverted>
                  <Loader active={loadingModal} />
                </Dimmer>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Agenciador</Table.HeaderCell>
                      <Table.HeaderCell>Transação</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {listaComissoesSelecionadas.map((fatura) => (
                      <Table.Row>
                        <Table.Cell>{fatura.idAgenciador + " - " + fatura.nomeAgenciador}</Table.Cell>
                        <Table.Cell>{fatura.numeroTransacaoVenda + "-" + fatura.prestacao}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Dimmer.Dimmable>
            </Modal.Content>
            <Modal.Actions>
              <Dimmer.Dimmable dimmed>
                <Dimmer active={loadingModal} inverted></Dimmer>
                <Form style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                  <Form.TextArea
                    style={{ textAlign: "left" }}
                    placeholder="Digite aqui o motivo da rejeição dessas comissões"
                    label="Informe o motivo das rejeições"
                    onChange={(e) => setFormMotivoRejeicao(e.target.value)}
                  />
                </Form>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button onClick={() => handleCloseModalRejeicaoMultipla()}>Não</Button>
                  <Button negative onClick={() => handleRejeitarComissaoMultipla()}>
                    Rejeitar Comissões
                  </Button>
                </div>
              </Dimmer.Dimmable>
            </Modal.Actions>
          </>
        )}
      </Modal>
    </div>
  );
}
