import { useEffect } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios";
import { Button, Container, Dimmer,Form, Header, Loader, Modal, Segment, Table } from "semantic-ui-react";
import AuthenticationService from "../main/AuthenticationService";
import { URL_PERFIS, URL_USUARIO } from "../main/api_calls";
import Util from "../main/util";
import MenuSistema from "../components/menu_sistema";

export default function AdministrarUsuarios(){
  const [usuarioLogado, setUsuarioLogado] = useState({})
  const navigate = useNavigate()
  const [listaUsuarios, setListaUsuarios] = useState([])
  const [listaPerfis, setListaPerfis] = useState([])

  const [idUsuario, setIdUsuario] = useState("")
  const [nome,setNome] = useState("")
  const [email,setEmail] = useState("")
  const [perfil,setPerfil] = useState("")
  const [senha, setSenha] = useState("")

  const [senhaTemp, setSenhaTemp] = useState("")

  const [openModalDeletar, setOpenModalDeletar] = useState(false)
  const [openModalUsuario, setOpenModalUsuario] = useState(false)
  const [openModalSenha, setOpenModalSenha] = useState(false)
  
  const [loadingTable, setLoadingTable] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false)

  useEffect(() => {
    if(AuthenticationService.isUserLoggedIn()){
      let usuario = AuthenticationService.getUsuario()
      if(usuario.perfil === "Admin"){
        setUsuarioLogado(usuario)
        axios.get(URL_USUARIO)
        .then(response => {

          setListaUsuarios(response.data)
          setLoadingTable(false)

          axios.get(URL_PERFIS)
          .then(response2 => {
            montarListaPefis(response2.data)
          })
          .catch((error) => { Util.tratarErro(error)})

        })
        .catch((error) => { Util.tratarErro(error)})
      }else{
        navigate("/consulta-vendas-agenciadas")
    }
    } else {
      AuthenticationService.logout()
    }
  },[])

  function montarListaPefis(perfis){
    let listaP = []
    perfis.forEach((perfil,i) => {
      let p = {
        key: i+perfil,
        value: perfil,
        text: perfil,
      }
      listaP.push(p)
    })
    setListaPerfis(listaP)
  }
  function recarregarListaUsuarios(){
    setLoadingTable(true)
    axios.get(URL_USUARIO)
    .then(response => {
      setListaUsuarios(response.data)
      setLoadingTable(false)
    })
    .catch((error) => { Util.tratarErro(error)})
  }
  //Modal Usuario
  function handleOpenModalUsuario(usuario){
    if(usuario){
      setNome(usuario.nome)
      setEmail(usuario.username)
      setPerfil(usuario.roles[0])
      setIdUsuario(usuario.id)
    }
    setOpenModalUsuario(true)
  }
  function handleCloseModalUsuario(){
    limparCampos()
    setOpenModalUsuario(false)
  }
  function cadastrarUsuario(){
    let form = {
      nome: nome.trim(),
      email: email.trim(),
      perfil: perfil.trim(),
    }
    if(senha.trim()){form.password = senha}
    if(form.nome && form.email && form.perfil){
      setLoadingModal(true)
      axios.post(URL_USUARIO, form)
      .then(response => {
        setLoadingModal(false)
        if(form.password){
          handleCloseModalUsuario()
        }else{
          handleCloseModalUsuario()
          setSenhaTemp(response.data.senhaTemporaria)
          handleOpenModalSenha()
        }
        recarregarListaUsuarios()
      })
      .catch(error => {
        if(error.response){
          error.response.data.errors.forEach(erro => {
            Util.notifyError(erro.defaultMessage)
          })
        }else{
          Util.notifyError("Ocorreu algum erro inesperado.")
        }
        setLoadingModal(false)})
    } else {
      Util.notifyError("Preencha todos os campos.")
    }
  }
  function atualizarUsuario(){
    let form = {
      nome: nome.trim(),
      email: email.trim(),
      perfil: perfil.trim(),
    }
    if(form.nome && form.email && form.perfil){
      setLoadingModal(true)
      axios.put(URL_USUARIO+"/"+idUsuario,form)
      .then(response => {
        setLoadingModal(false)
        handleCloseModalUsuario()
        recarregarListaUsuarios()
        Util.notifySuccess("Usuário atualizado com sucesso.")
      })
      .catch(error => {
        Util.tratarErro(error)
        setLoadingModal(false)})
    } else {
      Util.notifyError("Preencha todos os campos.")
    }
  }
  ////////////
  //Modal Deletar
  function handleOpenModalDeletar(usuario){
    setNome(usuario.nome)
    setIdUsuario(usuario.id)
    setOpenModalDeletar(true)
  }
  function handleCloseModalDeletar(){
    limparCampos()
    setOpenModalDeletar(false)
  }
  function deletarUsuario(){
    setLoadingModal(true)
    axios.delete(URL_USUARIO+"/"+idUsuario)
    .then(response => {
      setLoadingModal(false)
      handleCloseModalDeletar()
      recarregarListaUsuarios()
      Util.notifySuccess("Usuário removido com sucesso.")
    })
    .catch(error => {Util.tratarErro(error);setLoadingModal(false)})
  }
  ////////////
  //Modal Senha Temporaria
  function handleOpenModalSenha(){
    setOpenModalSenha(true)
  }
  function handleCloseModalSenha(){
    setOpenModalSenha(false)
    setSenhaTemp("")
  }
  ///////////
  function limparCampos(){
    setNome("")
    setEmail("")
    setPerfil("")
    setIdUsuario("")
  }

  return(
    <div>
      <MenuSistema openModal = {false} perfil={usuarioLogado.perfil}  nome={usuarioLogado.nome}/>
      <Container>
        <Header as='h2' textAlign="center" color="grey" className="page-header" >Administrar Usuários</Header>
        <div style={{display:'flex', flexWrap:'wrap', justifyContent:'space-between', marginBottom:'1rem'}} >
          <div style={{display:'flex', flexWrap:'wrap'}} >
            <Button
              label='Cadastrar Usuário'
              icon='user plus'
              color="blue"
              title='Clique aqui para cadastrar um novo usuário'
              onClick={() => handleOpenModalUsuario()}
            />
          </div>
        </div>
        <Dimmer.Dimmable dimmed>  
          <Dimmer active={loadingTable} inverted>
            <Loader active={loadingTable} />
          </Dimmer>
          <Segment basic style={{padding: '0 1px'}}>
          <Table color='blue' attached='bottom' celled fixed size="small" >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell title="RCA" width={7}>Nome</Table.HeaderCell>
                <Table.HeaderCell title="RCA" width={4}>E-mail</Table.HeaderCell>
                <Table.HeaderCell title="RCA" width={3}>Perfil</Table.HeaderCell>
                <Table.HeaderCell title="RCA" width={2}>Ações</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {listaUsuarios.map(usuario => (
                <Table.Row key={usuario.id}>
                  <Table.Cell title={usuario.nome} >{usuario.nome}</Table.Cell>
                  <Table.Cell title={usuario.username} >{usuario.username}</Table.Cell>
                  <Table.Cell title={usuario.perfil} >{usuario.roles[0]}</Table.Cell>
                  <Table.Cell textAlign='center' collapsing={false} singleLine={false}>
                    <Button 
                      inverted
                      circular 
                      icon='edit' 
                      color='blue'
                      title='Clique aqui para editar os dados deste usuário'
                      onClick={() => handleOpenModalUsuario(usuario)} /> &nbsp;
                    {!(usuario.roles[0] === "Admin") &&
                      <Button 
                        inverted
                        circular
                        disabled={usuario.roles[0] === "Admin"}
                        icon='trash' 
                        color='red' 
                        title='Clique aqui para remover este usuário'
                        onClick={() => handleOpenModalDeletar(usuario)}
                      />
                    }
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          </Segment>
        </Dimmer.Dimmable>
      </Container>
      {/* MODAL CADASTRAR/ATUALIZAR USUARIO */}
      <Modal
        size='small'
        centered
        closeIcon={false}
        closeOnDimmerClick={loadingModal? false: true}
        open={openModalUsuario}
        onClose={() => handleCloseModalUsuario()}
      >
        <Modal.Header style={{textAlign: 'center', backgroundColor: loadingModal? "lightblue" :'dodgerblue', color: loadingModal? "darkgrey" : "black"}} >{idUsuario? "Atualizar Usuário" : "Cadastrar Usuário"}</Modal.Header>
        <Modal.Content scrolling>
          <Dimmer.Dimmable dimmed>  
            <Dimmer active={loadingModal} inverted>
              <Loader active={loadingModal} ></Loader>
            </Dimmer>
            <Form style={{ marginTop:'1rem',marginBottom:'2rem',padding:'0 1px'}}>
              {!(perfil === "Admin") &&
                <Form.Select
                  fluid
                  search
                  label='Perfil'
                  required
                  placeholder="Escolha o perfil do usuário"
                  options={listaPerfis}
                  value={perfil}
                  onChange={(e, {value}) => setPerfil(value)}
                />
              }
              <Form.Input 
                type="text" 
                placeholder="Digite o Nome Completo" 
                style={{textAlign:'left', marginTop:'1rem'}} 
                label="Nome Completo"
                required
                value={nome}
                onChange={e => setNome(e.target.value)}
              />
              <Form.Input 
                type="email" 
                placeholder="Digite o E-mail" 
                style={{textAlign:'left', marginTop:'1rem'}} 
                label="E-mail"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              {!(idUsuario) &&
                <Form.Input 
                  type="password" 
                  placeholder="Digite a senha" 
                  style={{textAlign:'left', marginTop:'1rem'}} 
                  label="Senha"
                  value={senha}
                  onChange={e => setSenha(e.target.value)}
                />
              }
            </Form>
          </Dimmer.Dimmable>
        </Modal.Content>
        <Modal.Actions style={{textAlign:'center'}} >
        <Dimmer.Dimmable dimmed>  
          <Dimmer active={loadingModal} inverted>
          </Dimmer>
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <Button onClick={() => handleCloseModalUsuario()}>
              Fechar
            </Button>
            <Button color="blue" onClick={idUsuario? () => atualizarUsuario() : () => cadastrarUsuario()}>
              {idUsuario? "Atualizar" : "Cadastrar"}
            </Button>
          </div>
        </Dimmer.Dimmable>
        </Modal.Actions>
      </Modal>
      {/* MODAL DELETAR */}
      <Modal
        size='tiny'
        centered
        closeIcon={false}
        closeOnDimmerClick={loadingModal? false: true}
        open={openModalDeletar}
        onClose={() => handleCloseModalDeletar()}
      >
        <Modal.Header style={{textAlign: 'center', backgroundColor: loadingModal? "rgba(300,0,0,0.5)" :'indianred', color: loadingModal? "darkgrey" : "black"}} >Deseja remover este usuário?</Modal.Header>
        <Modal.Content style={{textAlign: 'center', fontSize: '1.5rem'}}>
          <Dimmer.Dimmable dimmed>  
            <Dimmer active={loadingModal} inverted>
              <Loader active={loadingModal} ></Loader>
            </Dimmer>
            <div style={{display: 'flex', flexDirection:'column', gap:'1rem', marginBottom:'2rem'}} >
              <span>Usuário:</span>
              <span><b>{nome}</b></span>
            </div>
          </Dimmer.Dimmable>
        </Modal.Content>
        <Modal.Actions style={{textAlign:'center'}} >
        <Dimmer.Dimmable dimmed>  
          <Dimmer active={loadingModal} inverted>
          </Dimmer>
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <Button onClick={() => handleCloseModalUsuario()}>
              Fechar
            </Button>
            <Button color="red" onClick={() => deletarUsuario()}>
              Remover
            </Button>
          </div>
        </Dimmer.Dimmable>
        </Modal.Actions>
      </Modal>
      {/* MODAL SENHA TEMPORARIA */}
      <Modal
        size='tiny'
        centered
        closeIcon={false}
        open={openModalSenha}
        onClose={() => handleCloseModalSenha()}
      >
        <Modal.Header style={{textAlign: 'center'}} >Usuário cadastrado com sucesso!</Modal.Header>
        <Modal.Content style={{textAlign: 'center', fontSize: '1.5rem'}}>
          <Dimmer.Dimmable dimmed>  
            <Dimmer active={loadingModal} inverted>
              <Loader active={loadingModal} ></Loader>
            </Dimmer>
            <div style={{display: 'flex', flexDirection:'column', gap:'1rem', marginBottom:'2rem'}} >
              <span>Senha Temporária:</span>
              <span><b>{senhaTemp}</b></span>
            </div>
          </Dimmer.Dimmable>
        </Modal.Content>
      </Modal>
    </div>
  )
}