import { Button, Card, Container, Dimmer, Form, Header, Icon, Loader, Menu, Modal, Segment, Table, TableCell } from "semantic-ui-react";
import MenuSistema from "../components/menu_sistema";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useEffect, useState } from "react";
import { pdf } from "@react-pdf/renderer";
import RelatorioPdfConsulta from "./relatorio_pdf_consulta";
import axios from "axios";
import {
  URL_AGENCIADOR,
  URL_APROVADO_POR,
  URL_COMISSOES,
  URL_CONSULTAR_LOTE,
  URL_CONSULTAR_STATUS_APRO,
  URL_CONSULTAR_STATUS_PGCOM,
  URL_DADOS_VENDA,
  URL_FILIAL,
  URL_LANCAMENTO,
  URL_OBTER_RELACIONAMENTO,
  URL_OBTER_RELACIONAMENTO_EMLOTE,
  URL_RCA,
  URL_STATUS_FATURA,
  URL_STATUS_PENDENTE,
  URL_USUARIO,
} from "../main/api_calls";
import Util from "../main/util";
import AuthenticationService from "../main/AuthenticationService";
import { useNavigate } from "react-router-dom";
import { CHAVE_WINTHOR_SERVICE } from "../constantes";

const selectPgCom = [
  { key: "1", value: "", text: "Selecione" },
  { key: "2", value: "Em Aberto", text: "Em Aberto" },
  { key: "3", value: "Pago", text: "Pago" },
  { key: "4", value: "Parcialmente Pago", text: "Parcialmente Pago" },
];
const selectStatusApro = [
  { key: "1", value: "", text: "Selecione" },
  { key: "2", value: "Aprovada", text: "Aprovada" },
  { key: "3", value: "Pendente", text: "Pendente" },
  { key: "4", value: "Rejeitada", text: "Rejeitada" },
];
export default function HistoricoVendas() {
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState({});

  const [menuFiltro, setMenuFiltro] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [faturaModal, setFaturaModal] = useState(null);

  const [listaFaturas, setListaFaturas] = useState([]);
  const [listaFiliais, setListaFiliais] = useState([]);
  const [listaAgenciadores, setListaAgenciadores] = useState([]);
  const [listaRCA, setListaRCA] = useState([]);
  const [listaStatusFatura, setListaStatusFatura] = useState([]);
  const [listaAprovadores, setListaAprovadores] = useState([]);

  const [filtroFilial, setFiltroFilial] = useState([]);
  const [filtroRca, setFiltroRca] = useState([]);
  const [filtroAgenciador, setFiltroAgenciador] = useState([]);
  const [filtroNota, setFiltroNota] = useState("");
  const [filtroTransacao, setFiltroTransacao] = useState("");
  const [filtroStatusFatura, setFiltroStatusFatura] = useState("");
  const [filtroPgCom, setFiltroPgCom] = useState("");
  const [filtroEmissaoInicial, setFiltroEmissaoInicial] = useState("");
  const [filtroEmissaoFinal, setFiltroEmissaoFinal] = useState("");
  const [filtroStatusAprovacao, setFiltroStatusAprovacao] = useState("");
  const [filtroAprovadoPor, setFiltroAprovadoPor] = useState("");

  const [loadingTable, setLoadingTable] = useState(true);
  const [habilitarPdf, setHabilitarPdf] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [totalQuitadas, setTotalQuitadas] = useState(0);
  const [totalAVencer, setTotalAVencer] = useState(0);
  const [totalVencidas, setTotalVencidas] = useState(0);

  // const [nReq, setNReq] = useState(0)

  function abrirModalFatura(fatura) {
    if (fatura.terminouLoading) {
      let modalF = fatura;
      setFaturaModal(modalF);
      setOpenModal(true);
      setLoadingModal(true);
      axios
        .get(URL_DADOS_VENDA + "?numeroTransacaoVenda=" + fatura.numeroTransacaoVenda + "&prestacao=" + fatura.prestacao)
        .then((response) => {
          if (response != undefined) {
            modalF.aprovacao = response.data.aprovada;
            modalF.aprovacaoComercial = response.data.aprovacaoComercial;
            modalF.aprovacaoFinanceiro = response.data.aprovacaoFinanceiro;
            modalF.comissaoPagaNoWinthor = response.data.comissaoPagaNoWinthor;
            modalF.dataAprovacaoComercial = response.data.dataAprovacaoComercial;
            modalF.dataAprovacaoFinanceiro = response.data.dataAprovacaoFinanceiro;
            modalF.dataPagamentoComissaoWinthor = response.data.dataPagamentoComissaoWinthor;
            modalF.motivoRejeicao = response.data.motivoRejeicao;
            modalF.rejeitada = response.data.rejeitada;
            modalF.tipoComissao = response.data.tipoComissao;
            modalF.valorComissaoSelecionada = response.data.valorComissaoSelecionada;
            modalF.responsavelAprovacaoComercial = response.data.responsavelAprovacaoComercial;
            modalF.responsavelAprovacaoFinanceiro = response.data.responsavelAprovacaoFinanceiro;
            modalF.valorAtualizado = response.data.valorAtualizado;
          }
          setFaturaModal(modalF);
          setLoadingModal(false);
        })
        .catch((error) => {
          setLoadingModal(false);
        });
    } else {
      Util.notify("Espere carregar todos os dados da fatura.");
    }
  }

  function fecharModalFatura() {
    setOpenModal(false);
    setFaturaModal(null);
  }

  async function montarPdfConsulta(data) {
    const blob = await pdf(<RelatorioPdfConsulta data={data} totalQuitadas={totalQuitadas} totalAVencer={totalAVencer} totalVencidas={totalVencidas} />).toBlob();
    let blobURL = URL.createObjectURL(blob);
    window.open(blobURL);
  }

  useEffect(() => {
    setLoadingTable(false);
    sessionStorage.setItem("nReq", 0);
    if (AuthenticationService.isUserLoggedIn()) {
      let usuario = AuthenticationService.getUsuario();
      setUsuario(usuario);
      let formData = new FormData();
      formData.append("chave", CHAVE_WINTHOR_SERVICE);

      axios
        .post(URL_FILIAL, formData)
        .then((response) => {
          carregarListaFilial(response.data);
        })
        .catch((error) => {
          Util.tratarErro(error);
        });

      axios
        .post(URL_AGENCIADOR, formData)
        .then((response) => {
          carregarListaAgenciador(response.data);
        })
        .catch((error) => {
          Util.tratarErro(error);
        });

      axios
        .post(URL_RCA, formData)
        .then((response) => {
          carregarListaRCA(response.data);
        })
        .catch((error) => {
          Util.tratarErro(error);
        });

      axios
        .post(URL_STATUS_FATURA, formData)
        .then((response) => {
          carregarListaStatusFatura(response.data);
        })
        .catch((error) => {
          Util.tratarErro(error);
        });

      axios
        .get(URL_USUARIO)
        .then((response) => {
          carregarListaAprovadores(response.data.filter((user) => user.roles[0] === "Comercial" || user.roles[0] === "Financeiro"));
        })
        .catch((error) => {
          Util.tratarErro(error);
        });
    } else {
      AuthenticationService.logout();
    }
  }, []);

  function carregarListaFilial(filiais) {
    let listaF = [];
    filiais.forEach((filial) => {
      let f = {
        key: filial.codigo,
        value: filial.codigo,
        text: filial.razaoSocial + " - " + filial.cidade,
      };
      listaF.push(f);
    });
    setListaFiliais(listaF);
  }

  function carregarListaAgenciador(agenciadores) {
    let listaA = [];
    agenciadores.forEach((a) => {
      let agenciador = {
        key: a.codusur,
        value: a.codusur,
        text: a.codusur + " - " + a.nome,
      };
      listaA.push(agenciador);
    });
    setListaAgenciadores(listaA);
  }

  function carregarListaRCA(rcas) {
    let listaR = [];
    rcas.forEach((r) => {
      let rca = {
        key: r.codusur,
        value: r.codusur,
        text: r.codusur + " - " + r.nome,
      };
      listaR.push(rca);
    });
    setListaRCA(listaR);
  }

  function carregarListaStatusFatura(ObjetoStatusFatura) {
    let listaStatusFatura = Object.values(ObjetoStatusFatura);
    let listaSF = [];
    let statusFatura = {
      key: "n",
      value: "",
      text: "Selecione",
    };
    listaSF.push(statusFatura);
    for (let i = 0; i < listaStatusFatura.length; i++) {
      statusFatura = {
        key: i,
        value: listaStatusFatura[i],
        text: listaStatusFatura[i],
      };
      listaSF.push(statusFatura);
    }
    setListaStatusFatura(listaSF);
  }

  function carregarListaAprovadores(users) {
    let listaU = [];
    let selecione = { key: "n", value: "", text: "Selecione" };
    listaU.push(selecione);
    users.forEach((u) => {
      let user = {
        key: u.id,
        value: u.nome,
        text: u.nome + " - " + u.roles[0],
      };
      listaU.push(user);
    });
    setListaAprovadores(listaU);
  }

  function filtrarComissões() {
    setLoadingTable(true);
    setHabilitarPdf(false);
    sessionStorage.setItem("nReq", parseInt(sessionStorage.getItem("nReq")) + 1);
    let nReqAtual = sessionStorage.getItem("nReq");

    if (filtroPgCom) {
      let formStatusPgCom = new FormData();
      formStatusPgCom.append("status", filtroPgCom);
      formStatusPgCom.append("chave", CHAVE_WINTHOR_SERVICE);
      axios
        .post(URL_CONSULTAR_STATUS_PGCOM, formStatusPgCom)
        .then((response1) => {
          if (response1.data) {
            let listaRecnum = response1.data.map((fatura) => fatura.recnum);
            if (listaRecnum.length > 0) {
              let formRecnum = new FormData();
              formRecnum.append("listaRecnum", listaRecnum);
              axios
                .post(URL_OBTER_RELACIONAMENTO_EMLOTE, formRecnum)
                .then((response2) => {
                  if (response2.data.length > 0) {
                    let listaTransacoes = response2.data.map((fatura) => fatura.comissaoVenda.numeroTransacaoVenda + "-" + fatura.comissaoVenda.prestacao);
                    let formConsultaLote = new FormData();
                    formConsultaLote.append("loteTransacao", listaTransacoes);
                    formConsultaLote.append("size", 100000);
                    formConsultaLote.append("chave", CHAVE_WINTHOR_SERVICE);

                    axios
                      .post(URL_CONSULTAR_LOTE, formConsultaLote)
                      .then((response2) => {
                        if (nReqAtual === sessionStorage.getItem("nReq")) {
                          completarListaFatura(response2.data.content, nReqAtual);
                          setLoadingTable(false);
                        }
                      })
                      .catch((error) => {
                        setLoadingTable(false);
                        setHabilitarPdf(true);
                        Util.tratarErro(error);
                      });
                  } else {
                    setListaFaturas([]);
                    setLoadingTable(false);
                    setHabilitarPdf(true);
                    setTotalQuitadas(0);
                    setTotalAVencer(0);
                    setTotalVencidas(0);
                  }
                })
                .catch((error) => {
                  setLoadingTable(false);
                  setHabilitarPdf(true);
                  Util.tratarErro(error);
                });
            } else {
              setListaFaturas([]);
              setLoadingTable(false);
              setHabilitarPdf(true);
              setTotalQuitadas(0);
              setTotalAVencer(0);
              setTotalVencidas(0);
            }
          } else {
            setListaFaturas([]);
            setLoadingTable(false);
            setHabilitarPdf(true);
            setTotalQuitadas(0);
            setTotalAVencer(0);
            setTotalVencidas(0);
          }
        })
        .catch((error) => {
          setLoadingTable(false);
          setHabilitarPdf(true);
          Util.tratarErro(error);
        });
    } else if (filtroStatusAprovacao) {
      let formStatusApro = new FormData();
      formStatusApro.append("statusAprovacao", filtroStatusAprovacao);
      axios
        .post(URL_CONSULTAR_STATUS_APRO, formStatusApro)
        .then((response1) => {
          if (response1.data.length > 0) {
            let url_consulta = filtroStatusAprovacao === "Pendente" ? URL_STATUS_PENDENTE : URL_CONSULTAR_LOTE;
            let listaTransacoes = response1.data.map((fatura) => fatura.numeroTransacaoVenda + "-" + fatura.prestacao);
            let formConsultaLote = new FormData();
            formConsultaLote.append("loteTransacao", listaTransacoes);
            formConsultaLote.append("size", 100000);
            formConsultaLote.append("chave", CHAVE_WINTHOR_SERVICE);

            axios
              .post(url_consulta, formConsultaLote)
              .then((response2) => {
                if (nReqAtual === sessionStorage.getItem("nReq")) {
                  completarListaFatura(response2.data.content, nReqAtual);
                  setLoadingTable(false);
                }
              })
              .catch((error) => {
                setLoadingTable(false);
                setHabilitarPdf(true);
                Util.tratarErro(error);
              });
          } else {
            setListaFaturas([]);
            setLoadingTable(false);
            setHabilitarPdf(true);
            setTotalQuitadas(0);
            setTotalAVencer(0);
            setTotalVencidas(0);
          }
        })
        .catch((error) => {
          setLoadingTable(false);
          setHabilitarPdf(true);
          Util.tratarErro(error);
        });
    } else if (filtroAprovadoPor) {
      let formAprovadoPor = new FormData();
      formAprovadoPor.append("responsavelAprovacao", filtroAprovadoPor);
      axios
        .post(URL_APROVADO_POR, formAprovadoPor)
        .then((response1) => {
          if (response1.data.length > 0) {
            let listaTransacoes = response1.data.map((fatura) => fatura.numeroTransacaoVenda + "-" + fatura.prestacao);
            let formConsultaLote = new FormData();
            formConsultaLote.append("loteTransacao", listaTransacoes);
            formConsultaLote.append("size", 100000);
            formConsultaLote.append("chave", CHAVE_WINTHOR_SERVICE);
            axios
              .post(URL_CONSULTAR_LOTE, formConsultaLote)
              .then((response2) => {
                if (nReqAtual === sessionStorage.getItem("nReq")) {
                  completarListaFatura(response2.data.content, nReqAtual);
                  setLoadingTable(false);
                }
              })
              .catch((error) => {
                setLoadingTable(false);
                setHabilitarPdf(true);
                Util.tratarErro(error);
              });
          } else {
            setListaFaturas([]);
            setLoadingTable(false);
            setHabilitarPdf(true);
            setTotalQuitadas(0);
            setTotalAVencer(0);
            setTotalVencidas(0);
          }
        })
        .catch((error) => {
          setLoadingTable(false);
          setHabilitarPdf(true);
          Util.tratarErro(error);
        });
    } else {
      let formData = new FormData();
      formData.append("chave", CHAVE_WINTHOR_SERVICE);
      formData.append("filiais", filtroFilial.join(","));
      formData.append("rca", filtroRca.join(","));
      formData.append("idsAgenciador", filtroAgenciador.map(id =>"'" + id + "'").join(","));
      formData.append("numeroNota", filtroNota);
      if (verificarTransacaoValida(filtroTransacao)) {
        formData.append("numeroTransacao", filtroTransacao);
      }
      formData.append("statusFatura", filtroStatusFatura);
      if (filtroEmissaoFinal) {
        formData.append("dataEmissaoInicial", filtroEmissaoFinal.toLocaleDateString("pt-BR"));
      }
      if (filtroEmissaoInicial) {
        formData.append("dataEmissaoFinal", filtroEmissaoInicial.toLocaleDateString("pt-BR"));
      }
      formData.append("size", 100000);

      axios
        .post(URL_COMISSOES, formData)
        .then((response) => {
          if (nReqAtual === sessionStorage.getItem("nReq")) {
            completarListaFatura(response.data.content, nReqAtual);
            setLoadingTable(false);
          }
        })
        .catch((error) => {
          setLoadingTable(false);
          setHabilitarPdf(true);
          Util.tratarErro(error);
        });
    }
  }

  function resetarFiltros() {
    setFiltroFilial([]);
    setFiltroRca([]);
    setFiltroAgenciador([]);
    setFiltroNota("");
    setFiltroTransacao("");
    setFiltroStatusFatura("");
    setFiltroEmissaoInicial("");
    setFiltroEmissaoFinal("");
    setFiltroPgCom("");
    setFiltroAprovadoPor("");
    setFiltroStatusAprovacao("");
  }

  useEffect(() => {
    if (
      filtroFilial.length > 0 ||
      filtroRca.length > 0 ||
      filtroAgenciador.length > 0 ||
      filtroNota ||
      filtroStatusFatura ||
      filtroEmissaoInicial ||
      filtroEmissaoFinal ||
      filtroPgCom ||
      filtroStatusAprovacao ||
      filtroAprovadoPor
    ) {
      filtrarComissões();
    } else {
      if (!filtroTransacao) {
        sessionStorage.setItem("nReq", parseInt(sessionStorage.getItem("nReq")) + 1);
        setListaFaturas([]);
        setLoadingTable(false);
        setHabilitarPdf(true);
        setTotalQuitadas(0);
        setTotalAVencer(0);
        setTotalVencidas(0);
      }
    }
  }, [
    filtroFilial,
    filtroRca,
    filtroAgenciador,
    filtroNota,
    filtroStatusFatura,
    filtroEmissaoInicial,
    filtroEmissaoFinal,
    filtroPgCom,
    filtroStatusAprovacao,
    filtroAprovadoPor,
    filtroTransacao,
  ]);
  useEffect(() => {
    let transacao = filtroTransacao;
    if (verificarTransacaoValida(transacao)) {
      filtrarComissões();
    }
  }, [filtroTransacao]);

  function verificarTransacaoValida(transacao) {
    if (transacao.length >= 6 ) {
      return true;
    }
    return false;
  }
  function verificarNumero(e) {
    const re = /^[0-9][\-\d]*(,\d+)?$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      return e.target.value;
    } else {
      return filtroTransacao;
    }
  }
  async function completarListaFatura(listaF, nReqAtual) {
    let tQuitadas = 0;
    let tAVencer = 0;
    let tVencidas = 0;
    if (listaF.length > 0) {
      let listaFaturasAtualizadas = listaF;
      for (const [i, fatura] of listaF.entries()) {
        let formData = new FormData();
        formData.append("numeroTransacaoVenda", fatura.numeroTransacaoVenda);
        formData.append("prestacao", fatura.prestacao);
        const response1 = await axios.post(URL_OBTER_RELACIONAMENTO, formData).catch((error) => {
          Util.logarErro(error);
          return {};
        });
        if (nReqAtual === sessionStorage.getItem("nReq")) {
          let faturaAtualizada = fatura;
          if (response1.data) {
            let formData2 = new FormData();
            formData2.append("chave", CHAVE_WINTHOR_SERVICE);
            const response2 = await axios.post(URL_LANCAMENTO + "/" + response1.data.recnum, formData2).catch((error) => {
              Util.logarErro(error);
              return {};
            });
            if (response2 != undefined) {
              faturaAtualizada.dataEncerramentoComercial = response2.data.dataEncerramentoComercial;
              faturaAtualizada.statusDesdobramento = response2.data.statusDesdobramento;
              faturaAtualizada.statusPagamentoComissao = response2.data.statusPagamentoComissao;
              faturaAtualizada.valorAPagar = response2.data.valorAPagar;
              if (fatura.valorAPagar || fatura.valorAPagar === 0) {
                if (fatura.statusFatura === "Quitada") {
                  tQuitadas = tQuitadas + fatura.valorAPagar;
                }
                if (fatura.statusFatura === "A Vencer") {
                  tAVencer = tAVencer + fatura.valorAPagar;
                }
                if (fatura.statusFatura === "Vencida") {
                  tVencidas = tVencidas + fatura.valorAPagar;
                }
              }
            }
          }
          faturaAtualizada.terminouLoading = true;
          listaFaturasAtualizadas = listaFaturasAtualizadas.map((fatura) => {
            if (fatura.numeroTransacaoVenda === faturaAtualizada.numeroTransacaoVenda && fatura.prestacao === faturaAtualizada.prestacao) {
              return faturaAtualizada;
            } else {
              return fatura;
            }
          });
          await setListaFaturas(
            listaFaturasAtualizadas.map((fatura) => {
              if (fatura.numeroTransacaoVenda === faturaAtualizada.numeroTransacaoVenda && fatura.prestacao === faturaAtualizada.prestacao) {
                return faturaAtualizada;
              } else {
                return fatura;
              }
            })
          );
        } else {
          break;
        }
      }
      if (nReqAtual === sessionStorage.getItem("nReq")) {
        setListaFaturas(listaFaturasAtualizadas);
        setHabilitarPdf(true);
        setLoadingTable(false);
        setTotalQuitadas(tQuitadas);
        setTotalAVencer(tAVencer);
        setTotalVencidas(tVencidas);
      }
    } else {
      setListaFaturas([]);
      setHabilitarPdf(true);
      setLoadingTable(false);
      setTotalQuitadas(0);
      setTotalAVencer(0);
      setTotalVencidas(0);
    }
  }

  return (
    <div>
      <MenuSistema openModal={openModal} perfil={usuario.perfil} nome={usuario.nome} />
      <Container>
        <Header as="h2" textAlign="center" color="grey" className="page-header">
          Consulta de Vendas Agenciadas
        </Header>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <Menu compact>
              <Menu.Item name="menuFiltro" active={menuFiltro === true} onClick={() => setMenuFiltro(!menuFiltro)}>
                <Icon name="filter" />
                Filtros
              </Menu.Item>
            </Menu>
            <Button
              label="Gerar PDF"
              icon="file pdf"
              color="red"
              disabled={!habilitarPdf}
              title="Clique aqui para gerar um relatório no formato PDF"
              style={{ marginLeft: "1rem" }}
              onClick={() => montarPdfConsulta(listaFaturas)}
            />
          </div>
        </div>
        {menuFiltro && (
          <Segment attached="bottom" className="filtro filtro-header" style={{ zIndex: openModal ? "900" : "1002" }}>
            {/* <Header as='h2' textAlign="left" color="grey" className="filtro-header" >Filtros</Header> */}
            <Form size="tiny" className="form-filtros">
              <Form.Group>
                <Form.Select
                  fluid
                  search
                  multiple
                  selection
                  clearable
                  label="Filial"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  placeholder="Filtrar por Filial"
                  options={listaFiliais}
                  value={filtroFilial}
                  onChange={(e, { value }) => setFiltroFilial(value)}
                  width={5}
                />
                <Form.Select
                  fluid
                  search
                  multiple
                  selection
                  clearable
                  label="RCA"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  placeholder="Filtrar por RCA"
                  options={listaRCA}
                  value={filtroRca}
                  onChange={(e, { value }) => setFiltroRca(value)}
                  width={5}
                />
                <Form.Select
                  fluid
                  search
                  multiple
                  selection
                  clearable
                  label="Agenciador"
                  placeholder="Filtrar por Agenciador"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  options={listaAgenciadores}
                  value={filtroAgenciador}
                  onChange={(e, { value }) => setFiltroAgenciador(value)}
                  width={6}
                />
              </Form.Group>
              <Form.Group>
                <Form.Input
                  fluid
                  label="Nº Nota"
                  placeholder="Filtrar por Nº Nota"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  value={filtroNota}
                  onChange={(e, { value }) => setFiltroNota(value)}
                  width={3}
                />
                <Form.Input
                  fluid
                  label="Transação"
                  placeholder="Filtrar por Transação"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  value={filtroTransacao}
                  onChange={(e, { value }) => setFiltroTransacao(value)}
                  width={3}
                />
                <Form.Select
                  fluid
                  search
                  label="Status Fatura"
                  placeholder="Filtrar por Status da Fatura"
                  disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                  options={listaStatusFatura}
                  value={filtroStatusFatura}
                  onChange={(e, { value }) => setFiltroStatusFatura(value)}
                  width={3}
                />
                <Form.Field className="datepicker-field" disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}>
                  <label>Data Emissão Inicial</label>
                  <div className="datepicker-input">
                    <SemanticDatepicker
                      locale="pt-BR"
                      format="DD/MM/YYYY"
                      disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                      value={filtroEmissaoInicial}
                      onChange={(e, { value }) => setFiltroEmissaoInicial(value)}
                    />
                  </div>
                </Form.Field>
                <Form.Field className="datepicker-field" disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}>
                  <label>Data Emissão Final</label>
                  <div className="datepicker-input">
                    <SemanticDatepicker
                      locale="pt-BR"
                      disabled={filtroPgCom || filtroStatusAprovacao || filtroAprovadoPor}
                      format="DD/MM/YYYY"
                      value={filtroEmissaoFinal}
                      onChange={(e, { value }) => setFiltroEmissaoFinal(value)}
                    />
                  </div>
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Select
                  fluid
                  search
                  label="Pg Com"
                  placeholder="Filtrar por Pg Com"
                  disabled={
                    filtroStatusAprovacao ||
                    filtroAprovadoPor ||
                    filtroFilial.length > 0 ||
                    filtroRca.length > 0 ||
                    filtroAgenciador.length > 0 ||
                    filtroNota ||
                    filtroTransacao ||
                    filtroStatusFatura ||
                    filtroEmissaoInicial ||
                    filtroEmissaoFinal
                  }
                  options={selectPgCom}
                  value={filtroPgCom}
                  onChange={(e, { value }) => setFiltroPgCom(value)}
                  width={4}
                />
                <Form.Select
                  fluid
                  search
                  label="Status da Aprovação"
                  placeholder="Filtrar por Status da Aprovação"
                  disabled={
                    filtroPgCom ||
                    filtroAprovadoPor ||
                    filtroFilial.length > 0 ||
                    filtroRca.length > 0 ||
                    filtroAgenciador.length > 0 ||
                    filtroNota ||
                    filtroTransacao ||
                    filtroStatusFatura ||
                    filtroEmissaoInicial ||
                    filtroEmissaoFinal
                  }
                  options={selectStatusApro}
                  value={filtroStatusAprovacao}
                  onChange={(e, { value }) => setFiltroStatusAprovacao(value)}
                  width={4}
                />
                <Form.Select
                  fluid
                  search
                  label="Aprovado Por"
                  placeholder="Filtrar por quem aprovou a comissão"
                  disabled={
                    filtroPgCom ||
                    filtroStatusAprovacao ||
                    filtroFilial.length > 0 ||
                    filtroRca.length > 0 ||
                    filtroAgenciador.length > 0 ||
                    filtroNota ||
                    filtroTransacao ||
                    filtroStatusFatura ||
                    filtroEmissaoInicial ||
                    filtroEmissaoFinal
                  }
                  options={listaAprovadores}
                  value={filtroAprovadoPor}
                  onChange={(e, { value }) => setFiltroAprovadoPor(value)}
                  width={4}
                />
                <Button
                  style={{ alignSelf: "end", marginLeft: ".2rem" }}
                  inverted
                  type="button"
                  circular
                  icon="eraser"
                  color="blue"
                  title="Clique aqui para limpar os campos de filtragem"
                  onClick={() => resetarFiltros()}
                />
              </Form.Group>
            </Form>
          </Segment>
        )}
        <Dimmer.Dimmable dimmed>
          <Dimmer active={loadingTable} inverted>
            <Loader active={loadingTable} />
          </Dimmer>
          <Segment basic style={{ padding: "0 1px" }}>
            <Header as="h3" color="green" attached="top" textAlign="center">
              Faturas Quitadas
            </Header>
            <Table color="blue" attached="bottom" sortable celled fixed size="small" selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell title="RCA" width={2}>
                    RCA
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Agenciador" width={5}>
                    Agenciador
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Consumidor Final" width={4}>
                    Consumidor Final
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Nº NF-Parcela" width={3}>
                    Nº NF-Parcela
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Forma Pagamento" width={3}>
                    Forma Pagamento
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Emissão" width={3}>
                    Emissão
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Vencimento" width={3}>
                    Vencimento
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Valor Total" width={3}>
                    Valor Total
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Valor a Pagar" width={3}>
                    Valor a Pagar
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Status" width={3}>
                    Status
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {listaFaturas.map((fatura) => (
                  <>
                    {fatura.statusFatura === "Quitada" && (
                      <Table.Row key={fatura.numeroTransacaoVenda + fatura.prestacao} style={{ cursor: "pointer" }} onClick={() => abrirModalFatura(fatura)}>
                        <Table.Cell title={fatura.idRCA}>{fatura.idRCA}</Table.Cell>
                        <Table.Cell title={fatura.nomeAgenciador}>{fatura.nomeAgenciador}</Table.Cell>
                        <Table.Cell title={fatura.nomeCliente}>{fatura.nomeCliente}</Table.Cell>
                        <Table.Cell title={fatura.numeroNota + " - " + fatura.prestacao}>{fatura.numeroNota + " - " + fatura.prestacao}</Table.Cell>
                        <Table.Cell title={fatura.tipoCobranca}>{fatura.tipoCobranca}</Table.Cell>
                        <Table.Cell title={Util.exibirDataFormatada(fatura.dataEmissao)}>{Util.exibirDataFormatada(fatura.dataEmissao)}</Table.Cell>
                        <Table.Cell title={Util.exibirDataFormatada(fatura.dataVencimento)}>{Util.exibirDataFormatada(fatura.dataVencimento)}</Table.Cell>
                        <Table.Cell textAlign="right" title={Util.exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}>
                          {Util.exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}
                        </Table.Cell>
                        <Table.Cell textAlign="right" title={Util.exibirValorMonetarioBr(fatura.valorAPagar)}>
                          <Dimmer.Dimmable dimmed>
                            <Dimmer active={!fatura.terminouLoading} inverted>
                              <Loader active={!fatura.terminouLoading} />
                            </Dimmer>
                            {fatura.terminouLoading ? (
                              <>{fatura.valorAPagar || fatura.valorAPagar === 0 ? Util.exibirValorMonetarioBr(fatura.valorAPagar) : ""}</>
                            ) : (
                              <span>Carregando</span>
                            )}
                          </Dimmer.Dimmable>
                        </Table.Cell>
                        <Table.Cell textAlign="left" title={Util.exibirValorMonetarioBr(fatura.statusPagamentoComissao)}>
                          <Dimmer.Dimmable dimmed>
                            <Dimmer active={!fatura.terminouLoading} inverted>
                              <Loader active={!fatura.terminouLoading} />
                            </Dimmer>
                            {fatura.terminouLoading ? <>{fatura.statusPagamentoComissao}</> : <span>Carregando</span>}
                          </Dimmer.Dimmable>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </>
                ))}
                <Table.Row>
                  <TableCell style={{ fontSize: "1rem" }} textAlign="right" colSpan={8}>
                    <b>Total:</b>
                  </TableCell>
                  <TableCell style={{ fontSize: "1rem" }} textAlign="right">
                    <Dimmer.Dimmable dimmed>
                      <Dimmer active={!habilitarPdf} inverted>
                        <Loader active={!habilitarPdf} />
                      </Dimmer>
                      {habilitarPdf ? <>{Util.exibirValorMonetarioBr(totalQuitadas)}</> : <span>Carregando</span>}
                    </Dimmer.Dimmable>
                  </TableCell>
                  <TableCell colSpan={2}></TableCell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Header as="h3" color="yellow" attached="top" textAlign="center">
              Faturas a Vencer
            </Header>
            <Table color="blue" attached="bottom" sortable celled fixed size="small" selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell title="RCA" width={2}>
                    RCA
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Agenciador" width={5}>
                    Agenciador
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Consumidor Final" width={4}>
                    Consumidor Final
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Nº NF-Parcela" width={3}>
                    Nº NF-Parcela
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Forma Pagamento" width={3}>
                    Forma Pagamento
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Emissão" width={3}>
                    Emissão
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Vencimento" width={3}>
                    Vencimento
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Valor Total" width={3}>
                    Valor Total
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Valor a Pagar" width={3}>
                    Valor a Pagar
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Status" width={3}>
                    Status
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {listaFaturas.map((fatura) => (
                  <>
                    {fatura.statusFatura === "A Vencer" && (
                      <Table.Row key={fatura.numeroTransacaoVenda + fatura.prestacao} style={{ cursor: "pointer" }} onClick={() => abrirModalFatura(fatura)}>
                        <Table.Cell title={fatura.idRCA}>{fatura.idRCA}</Table.Cell>
                        <Table.Cell title={fatura.nomeAgenciador}>{fatura.nomeAgenciador}</Table.Cell>
                        <Table.Cell title={fatura.nomeCliente}>{fatura.nomeCliente}</Table.Cell>
                        <Table.Cell title={fatura.numeroNota + " - " + fatura.prestacao}>{fatura.numeroNota + " - " + fatura.prestacao}</Table.Cell>
                        <Table.Cell title={fatura.tipoCobranca}>{fatura.tipoCobranca}</Table.Cell>
                        <Table.Cell title={Util.exibirDataFormatada(fatura.dataEmissao)}>{Util.exibirDataFormatada(fatura.dataEmissao)}</Table.Cell>
                        <Table.Cell title={Util.exibirDataFormatada(fatura.dataVencimento)}>{Util.exibirDataFormatada(fatura.dataVencimento)}</Table.Cell>
                        <Table.Cell textAlign="right" title={Util.exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}>
                          {Util.exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}
                        </Table.Cell>
                        <Table.Cell textAlign="right" title={Util.exibirValorMonetarioBr(fatura.valorAPagar)}>
                          <Dimmer.Dimmable dimmed>
                            <Dimmer active={!fatura.terminouLoading} inverted>
                              <Loader active={!fatura.terminouLoading} />
                            </Dimmer>
                            {fatura.terminouLoading ? (
                              <>{fatura.valorAPagar || fatura.valorAPagar === 0 ? Util.exibirValorMonetarioBr(fatura.valorAPagar) : ""}</>
                            ) : (
                              <span>Carregando</span>
                            )}
                          </Dimmer.Dimmable>
                        </Table.Cell>
                        <Table.Cell textAlign="left" title={Util.exibirValorMonetarioBr(fatura.statusPagamentoComissao)}>
                          <Dimmer.Dimmable dimmed>
                            <Dimmer active={!fatura.terminouLoading} inverted>
                              <Loader active={!fatura.terminouLoading} />
                            </Dimmer>
                            {fatura.terminouLoading ? <>{fatura.statusPagamentoComissao}</> : <span>Carregando</span>}
                          </Dimmer.Dimmable>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </>
                ))}
                <Table.Row>
                  <TableCell style={{ fontSize: "1rem" }} textAlign="right" colSpan={8}>
                    <b>Total:</b>
                  </TableCell>
                  <TableCell style={{ fontSize: "1rem" }} textAlign="right">
                    <Dimmer.Dimmable dimmed>
                      <Dimmer active={!habilitarPdf} inverted>
                        <Loader active={!habilitarPdf} />
                      </Dimmer>
                      {habilitarPdf ? <>{Util.exibirValorMonetarioBr(totalAVencer)}</> : <span>Carregando</span>}
                    </Dimmer.Dimmable>
                  </TableCell>
                  <TableCell colSpan={2}></TableCell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Header as="h3" color="red" attached="top" textAlign="center">
              Faturas em Aberto (Vencidas)
            </Header>
            <Table color="blue" attached="bottom" sortable celled fixed size="small" selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell title="RCA" width={2}>
                    RCA
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Agenciador" width={5}>
                    Agenciador
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Consumidor Final" width={4}>
                    Consumidor Final
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Nº NF-Parcela" width={3}>
                    Nº NF-Parcela
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Forma Pagamento" width={3}>
                    Forma Pagamento
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Emissão" width={3}>
                    Emissão
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Vencimento" width={3}>
                    Vencimento
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Valor Total" width={3}>
                    Valor Total
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Valor a Pagar" width={3}>
                    Valor a Pagar
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Status" width={3}>
                    Status
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {listaFaturas.map((fatura) => (
                  <>
                    {fatura.statusFatura === "Vencida" && (
                      <Table.Row key={fatura.numeroTransacaoVenda + fatura.prestacao} style={{ cursor: "pointer" }} onClick={() => abrirModalFatura(fatura)}>
                        <Table.Cell title={fatura.idRCA}>{fatura.idRCA}</Table.Cell>
                        <Table.Cell title={fatura.nomeAgenciador}>{fatura.nomeAgenciador}</Table.Cell>
                        <Table.Cell title={fatura.nomeCliente}>{fatura.nomeCliente}</Table.Cell>
                        <Table.Cell title={fatura.numeroNota + " - " + fatura.prestacao}>{fatura.numeroNota + " - " + fatura.prestacao}</Table.Cell>
                        <Table.Cell title={fatura.tipoCobranca}>{fatura.tipoCobranca}</Table.Cell>
                        <Table.Cell title={Util.exibirDataFormatada(fatura.dataEmissao)}>{Util.exibirDataFormatada(fatura.dataEmissao)}</Table.Cell>
                        <Table.Cell title={Util.exibirDataFormatada(fatura.dataVencimento)}>{Util.exibirDataFormatada(fatura.dataVencimento)}</Table.Cell>
                        <Table.Cell textAlign="right" title={Util.exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}>
                          {Util.exibirValorMonetarioBr(fatura.valorComissaoCompraBoleto)}
                        </Table.Cell>
                        <Table.Cell textAlign="right" title={Util.exibirValorMonetarioBr(fatura.valorAPagar)}>
                          <Dimmer.Dimmable dimmed>
                            <Dimmer active={!fatura.terminouLoading} inverted>
                              <Loader active={!fatura.terminouLoading} />
                            </Dimmer>
                            {fatura.terminouLoading ? (
                              <>{fatura.valorAPagar || fatura.valorAPagar === 0 ? Util.exibirValorMonetarioBr(fatura.valorAPagar) : ""}</>
                            ) : (
                              <span>Carregando</span>
                            )}
                          </Dimmer.Dimmable>
                        </Table.Cell>
                        <Table.Cell textAlign="left" title={Util.exibirValorMonetarioBr(fatura.statusPagamentoComissao)}>
                          <Dimmer.Dimmable dimmed>
                            <Dimmer active={!fatura.terminouLoading} inverted>
                              <Loader active={!fatura.terminouLoading} />
                            </Dimmer>
                            {fatura.terminouLoading ? <>{fatura.statusPagamentoComissao}</> : <span>Carregando</span>}
                          </Dimmer.Dimmable>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </>
                ))}
                <Table.Row>
                  <TableCell style={{ fontSize: "1rem" }} textAlign="right" colSpan={8}>
                    <b>Total:</b>
                  </TableCell>
                  <TableCell style={{ fontSize: "1rem" }} textAlign="right">
                    <Dimmer.Dimmable dimmed>
                      <Dimmer active={!habilitarPdf} inverted>
                        <Loader active={!habilitarPdf} />
                      </Dimmer>
                      {habilitarPdf ? <>{Util.exibirValorMonetarioBr(totalVencidas)}</> : <span>Carregando</span>}
                    </Dimmer.Dimmable>
                  </TableCell>
                  <TableCell colSpan={2}></TableCell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Segment>
        </Dimmer.Dimmable>
      </Container>
      <Modal closeIcon centered size={"large"} open={openModal} onClose={() => fecharModalFatura(false)}>
        {faturaModal && (
          <Modal.Content>
            <Dimmer.Dimmable dimmed>
              <Dimmer active={loadingModal} inverted>
                <Loader active={loadingModal} />
              </Dimmer>
              <Segment basic style={{ padding: "2px" }}>
                <Card fluid color="blue">
                  <Card.Content className="fatura__card__header" style={{ paddingBottom: ".3rem", paddingTop: "1rem" }}>
                    <Card.Header>Agenciador: {faturaModal.nomeAgenciador}</Card.Header>
                    {faturaModal.statusFatura === "Quitada" && <Card.Header style={{ color: "limegreen" }}>{faturaModal.statusFatura}</Card.Header>}
                    {faturaModal.statusFatura === "A Vencer" && <Card.Header style={{ color: "darkorange" }}>{faturaModal.statusFatura}</Card.Header>}
                    {faturaModal.statusFatura === "Vencida" && <Card.Header style={{ color: "orangered" }}>{faturaModal.statusFatura}</Card.Header>}
                  </Card.Content>
                  <Card.Content>
                    <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
                      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginBottom: ".5rem", justifyContent: "space-between" }}>
                        <div className="fatura__card__column" style={{ display: "flex", flexDirection: "column" }}>
                          <span>
                            <b>Cliente: &nbsp; {faturaModal.nomeCliente}</b>
                          </span>
                          <span>Emissão: &nbsp; {Util.exibirDataFormatada(faturaModal.dataEmissao)}</span>
                          <span>Quitação: &nbsp; {Util.exibirDataFormatada(faturaModal.dataQuitacao)}</span>
                          <span>Vencimento: &nbsp; {Util.exibirDataFormatada(faturaModal.dataVencimento)}</span>
                          <span>
                            Transação: <b>{faturaModal.numeroTransacaoVenda + "-" + faturaModal.prestacao}</b>
                          </span>
                          <span>
                            Região do pedido: &nbsp; <b>{faturaModal.regiaoPedido}</b>{" "}
                          </span>
                        </div>
                        <div className="fatura__card__column" style={{ display: "flex", flexDirection: "column" }}>
                          <span>
                            Cobrança: &nbsp; <b>{faturaModal.tipoCobranca}</b>
                          </span>
                          <span>Cod. Profissional: &nbsp; {faturaModal.codigoProfissional}</span>
                          <span>Cod. Vendedor: &nbsp; {faturaModal.idRCA}</span>
                          <span>Dt. Devol: &nbsp; {Util.exibirDataFormatada(faturaModal.dataDevolucao)}</span>
                          <span>Vl. Devol: &nbsp; {faturaModal.dataDevolucao ? Util.exibirValorMonetarioBr(faturaModal.valorDevolvido) : ""}</span>
                        </div>
                        <div className="fatura__card__column" style={{ display: "flex", flexDirection: "column" }}>
                          <span>Vl. Vendido: &nbsp; {Util.exibirValorMonetarioBr(faturaModal.valorVenvido)}</span>
                          <span>Vl. Tabela PSD: &nbsp; {Util.exibirValorMonetarioBr(faturaModal.valorTabelaPSD)}</span>
                          <span>Percentual: &nbsp; {faturaModal.percentual + "%"}</span>
                          <span>Nota: &nbsp; {faturaModal.numeroNota}</span>
                          <span>Parcela: &nbsp; {faturaModal.prestacao}</span>
                          <span>Vl. Parcela: &nbsp; {Util.exibirValorMonetarioBr(faturaModal.valorParcela)}</span>
                        </div>
                        <div className="fatura__card__column fatura_card_column--radio" style={{ display: "flex", flexDirection: "column" }}>
                          {faturaModal.rejeitada === true ? (
                            <span style={{ fontWeight: "bold" }}>
                              Status da aprovação: &nbsp; <b style={{ color: "red" }}>{"Rejeitada"}</b>
                            </span>
                          ) : (
                            <span style={{ fontWeight: "bold" }}>
                              Status da aprovação: &nbsp; <b style={{ color: faturaModal.aprovacao ? "royalblue" : "gold" }}>{faturaModal.aprovacao ? "Aprovada" : "Pendente"}</b>
                            </span>
                          )}
                          {faturaModal.rejeitada === true && <span style={{ fontWeight: "bold" }}>Motivo rejeição: &nbsp; {faturaModal.motivoRejeicao}</span>}
                          <span>
                            <b>Pg. Com.: &nbsp; {faturaModal.statusPagamentoComissao}</b>
                          </span>
                          <span>
                            <b>Dt. Encerramento Com.: &nbsp; {Util.exibirDataFormatada(faturaModal.dataEncerramentoComercial)}</b>
                          </span>
                          <span>
                            <b>Vl. A Pagar: &nbsp; {faturaModal.valorAPagar || faturaModal.valorAPagar === 0 ? Util.exibirValorMonetarioBr(faturaModal.valorAPagar) : ""}</b>
                          </span>
                          {faturaModal.statusDesdobramento && (
                            <span style={{ fontWeight: "bold" }}>
                              Status Desdobramento: &nbsp;<b style={{ color: "red" }}> {faturaModal.statusDesdobramento}</b>
                            </span>
                          )}
                          {faturaModal.aprovacao === true && (
                            <>
                              <span>
                                <b>Tipo Comissão: &nbsp; {faturaModal.tipoComissao ? faturaModal.tipoComissao.replace(/_/g, " ").replace("c", "C") : ""}</b>
                              </span>
                              <span>
                                <b>Vl. Comissão: &nbsp; {Util.exibirValorMonetarioBr(faturaModal.valorComissaoSelecionada)}</b>
                              </span>
                              <br />
                              {faturaModal.tipoComissao !== "comissao_bruta" && (
                                <span style={{ opacity: ".45" }}>
                                  <b>Comissão bruta: &nbsp; {Util.exibirValorMonetarioBr(faturaModal.valorComissaoBruta)}</b>
                                </span>
                              )}
                              {faturaModal.tipoComissao !== "comissao_com_nfe" && (
                                <span style={{ opacity: ".45" }}>
                                  <b>Comissão com nfe: &nbsp; {Util.exibirValorMonetarioBr(faturaModal.valorComissaoNfe)}</b>
                                </span>
                              )}
                              {faturaModal.tipoComissao !== "comissao_sem_nfe" && (
                                <span style={{ opacity: ".45" }}>
                                  <b>Comissão sem nfe: &nbsp; {Util.exibirValorMonetarioBr(faturaModal.valorComissaoSemNfe)}</b>
                                </span>
                              )}
                              {faturaModal.tipoComissao !== "comissao_compra_boleto" && (
                                <span style={{ opacity: ".45" }}>
                                  <b>Comissão compra/boleto: &nbsp; {Util.exibirValorMonetarioBr(faturaModal.valorComissaoCompraBoleto)}</b>
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}></div>
                    </div>
                  </Card.Content>
                  <Card.Content style={{ paddingBottom: ".6rem", paddingTop: ".4rem" }}>
                    {faturaModal.aprovacaoComercial === true && (
                      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "2rem" }}>
                        <span style={{ width: "35%" }}>
                          Aprovada no Comercial: &nbsp;<b>{faturaModal.responsavelAprovacaoComercial}</b>
                        </span>
                        <span>
                          Data da Aprovação: &nbsp;<b>{Util.exibirDataFormatada(faturaModal.dataAprovacaoComercial)}</b>
                        </span>
                      </div>
                    )}
                    {faturaModal.aprovacaoFinanceiro === true && (
                      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "2rem" }}>
                        <span style={{ width: "35%" }}>
                          Aprovada no Financeiro: &nbsp;<b>{faturaModal.responsavelAprovacaoFinanceiro}</b>
                        </span>
                        <span>
                          Data da Aprovação: &nbsp;<b>{Util.exibirDataFormatada(faturaModal.dataAprovacaoFinanceiro)}</b>
                        </span>
                      </div>
                    )}
                  </Card.Content>
                </Card>
              </Segment>
            </Dimmer.Dimmable>
          </Modal.Content>
        )}
      </Modal>
    </div>
  );
}
