import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, Grid, Icon, Image, Input, Segment } from "semantic-ui-react";
import AuthenticationService from "../main/AuthenticationService";
import Util from "../main/util";

export default function PageLogin() {
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [senha, setSenha] = useState("");

  function entrar() {
    if (username !== "" && senha !== "") {
      AuthenticationService.executeJwtAuthenticationService(username, senha)
        .then((response) => {
          AuthenticationService.registerSuccessfulLoginForJwt(response.data);
          if (response.data.perfil === "Consulta") {
            navigate("/consulta-vendas-agenciadas");
          } else {
            navigate("/gestao-comissoes");
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              Util.notifyError("E-mail ou Senha inválido.");
            } else {
              Util.notifyError(error.response.data.message);
            }
          } else {
            Util.notifyError("Ocorreu algum erro inesperado.");
          }
        });
    }
  }

  return (
    <Container>
      <Grid textAlign="center" verticalAlign="middle" style={{ height: "100vh" }}>
        <Grid.Column style={{ maxWidth: 500 }}>
          <br />
          <Segment color="blue" textAlign="left" style={{ padding: "2rem", paddingTop: "2rem" }}>
            <Image alt="logo kgm" src="/kgm-lan-logotipo.png" size="small" centered style={{ marginBottom: "3rem" }} />
            <p style={{ color: "grey", fontSize: 14 }}> Faça o login para continuar.</p>
            <Form size="large">
              <Form.Input
                fluid
                content="{this.state.username}"
                icon="user"
                iconPosition="left"
                placeholder="E-mail"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                maxLength="100"
              />
              <Form.Field>
                <Input fluid placeholder="Senha" value={senha} onChange={(e) => setSenha(e.target.value)} type={mostrarSenha ? "text" : "password"} iconPosition="left" icon action>
                  <Icon name="lock" />
                  <input />
                  <Button icon={mostrarSenha ? "eye" : "eye slash"} basic onClick={(e) => setMostrarSenha(!mostrarSenha)}></Button>
                </Input>
              </Form.Field>

              <br />

              <Button circular fluid size="large" color="blue" icon="sign in alternate" content="Entrar" onClick={() => entrar()} />
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
