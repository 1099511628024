import { Route, HashRouter, Routes } from "react-router-dom";
import ControleComissoes from "../views/page_controle_comissoes";
import HistoricoVendas from "../views/page_historico_vendas";
import PageLogin from "../views/page_login";
import axios from "axios";
import AuthenticationService from "./AuthenticationService";
import AdministrarUsuarios from "../views/page_administrar_usuarios";
import AlterarSenha from "../views/page_alterar_senha";

axios.interceptors.request.use(request => {
  if (AuthenticationService.isUserLoggedIn()){
    const token = AuthenticationService.getToken()
    request.headers.Authorization =  'Bearer ' + token;
  }
  return request;
});
axios.interceptors.response.use(response => {
  return response
}, (error) => {
  if(error.response){
      if(error.response.status === 401 && AuthenticationService.isUserLoggedIn()){
        console.log('sem auth')
        AuthenticationService.removerTokens()
        AuthenticationService.logout()
        window.location = "/"
      } else {
        return Promise.reject(error)
      }
  }
})

export default function Rotas(){
  return(
    <HashRouter>
      <Routes>
        <Route path="/" element={<PageLogin/>} ></Route>
        <Route path="/gestao-comissoes" element={<ControleComissoes/>} ></Route>
        <Route path="/consulta-vendas-agenciadas" element={<HistoricoVendas/>} ></Route>
        <Route path="/administrar-usuarios" element={<AdministrarUsuarios/>} ></Route>
        <Route path="/alterar-senha" element={<AlterarSenha/>} ></Route>
      </Routes>
    </HashRouter>
  )
}