const CONSTANTES = require('../constantes');

//URL da API
export const URL_API_WINTHOR = CONSTANTES.URL_API_WINTHOR
export const URL_API_APRO = CONSTANTES.URL_API_APRO
export const URL_API_RELACIONAMENTO = CONSTANTES.URL_API_RELACIONAMENTO
export const URL_API_ACESSO = CONSTANTES.URL_API_ACESSO

//Comissão
export const URL_COMISSOES = URL_API_WINTHOR + '/vendas-agenciadas'

//Filial
export const URL_FILIAL = URL_API_WINTHOR + "/filial"

//Agenciador
export const URL_AGENCIADOR = URL_API_WINTHOR + "/usuario/listar-agenciadores"

//RCA
export const URL_RCA = URL_API_WINTHOR + "/usuario/listar-rca"

//Status Fatura
export const URL_STATUS_FATURA = URL_API_WINTHOR + "/vendas-agenciadas/status-fatura"

//Aprovar Comercial
export const URL_APROVAR_COMERCIAL = URL_API_APRO + "/comissao-venda/aprovar-comercial"

//Aprovar Financeiro
export const URL_APROVAR_FINANCEIRO = URL_API_APRO + "/comissao-venda/aprovar-financeiro"

//Rejeitar Comissão
export const URL_REJEITAR = URL_API_APRO + "/comissao-venda/rejeitar-comissao"

//Obter Dados da Venda
export const URL_DADOS_VENDA = URL_API_APRO + "/comissao-venda/obter-dados-venda"

//Lançamento
export const URL_LANCAMENTO = URL_API_WINTHOR + "/lancamento"

//Criar Relacionamento Comissão
export const URL_NOVO_RELACIONAMENTO = URL_API_RELACIONAMENTO +  "/comissao-venda-lacamento"

//Obter Relacionamento Comissão
export const URL_OBTER_RELACIONAMENTO = URL_API_RELACIONAMENTO + "/comissao-venda-lacamento/obter-relacionamento"

//Consultar por Status Aprovação
export const URL_CONSULTAR_STATUS_APRO = URL_API_APRO + "/comissao-venda/consultar-por-statusaprovacao"

//Consultar por Aprovador
export const URL_APROVADO_POR = URL_API_APRO + "/comissao-venda/consultar-por-responsavel"

//Consultar em Lote
export const URL_CONSULTAR_LOTE = URL_API_WINTHOR + "/vendas-agenciadas/consultar-em-lote"

//Filtrar por Status Aprovação Pendente
export const URL_STATUS_PENDENTE = URL_API_WINTHOR + "/vendas-agenciadas/consultar-pendentes-aprovacao"

//Consultar por Status Pg Com
export const URL_CONSULTAR_STATUS_PGCOM = URL_API_WINTHOR + "/lancamento/consultar-por-status"

//Obter Relacionamento em Lote
export const URL_OBTER_RELACIONAMENTO_EMLOTE = URL_API_RELACIONAMENTO + "/comissao-venda-lacamento/obter-relacionamento-emlote"

//Login
export const URL_LOGIN = URL_API_ACESSO + "/login"

//Usuarios
export const URL_USUARIO = URL_API_ACESSO + "/usuario"

//Obter Perfis
export const URL_PERFIS = URL_API_ACESSO + "/usuario/perfis"

